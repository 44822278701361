import React, { useState, useEffect } from "react";
import RssButton from "../lists/RssButton";
import Axios from "axios";

let Clock = ({ darkmode, preferences, has1p, togglePrefSettings }) => {
  let [clockOn, setClockOn] = useState(preferences?.clockx1 || false);

  useEffect(() => {
    togglePrefSettings("clockx1", clockOn);
  }, [clockOn]);

  let toggle = () => {
    togglePrefSettings("clockx1", !clockOn);
    setClockOn(!clockOn);
  };

  console.log("preferences.timezone", preferences.timezone);

  let timeZone;
  switch (preferences.timezone) {
    case "PST":
      timeZone = "America/Los_Angeles";
      break;
    case "MST":
      timeZone = "America/Denver";
      break;
    case "CST":
      timeZone = "America/Chicago";
      break;
    case "EST":
      timeZone = "America/New_York";
      break;
    default:
      timeZone = "America/Denver"; // Default to MST
  }

  let timeNow = new Date().toLocaleTimeString("en-US", { timeZone: timeZone, hour: "2-digit", minute: "2-digit" });
  console.log("timeNow", timeNow);
  let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let dayOfWeek = weekday[new Date().getDay()];
  let monthAndDay = new Date().toLocaleDateString("en-US", { month: "short", day: "numeric" });

  if (!has1p) {
    return null;
  }

  return (
    <div style={{ minWidth: 360 }}>
      <div
        className={"graphTextName"}
        style={{ fontSize: 16, alignSelf: "flex-start", color: darkmode ? "#ccc" : "#444", marginBottom: 6, marginTop: 6 }}
      >
        Stream Clock:
      </div>
      <div
        onClick={() => toggle()}
        className={darkmode ? "prefBoxDark settingsText" : "prefBox settingsText"}
        style={{ alignSelf: "stretch", alignItems: "flex-start", flexDirection: "row", alignItems: "center", paddingTop: 6 }}
      >
        <RssButton activeRow={true} inactive={!clockOn} darkmode={darkmode} offOpacity={0.08} marginLeft={-2} />
        <div style={{ flexDirection: "column", alignItems: "center" }}>
          <div className={"settingsText"} style={{ marginLeft: 4 }}>
            {dayOfWeek} {timeNow}, {monthAndDay}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clock;
