import React, { Component } from "react";
import Switch from "react-input-switch";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
//import { DatePicker, DatePickerInput } from "@carbon/react";
import DatePicker from "react-date-picker"; //"react-date-picker/dist/entry.nostyle";
import { getDatabase, ref, set } from "firebase/database";

import turnOff from "../../imgs/turnOff.png";
import turnOffWhite from "../../imgs/turnOffWhite.png";
import closeX from "../../imgs/closeX.png";
import closeXWhite from "../../imgs/closeXWhite.png";
import closeXDark from "../../imgs/closeXDark.png";
import signalWhite from "../../imgs/signalWhite.png";
import signal from "../../imgs/signal.png";
import RssButton from "./RssButton";

class ListPortfolio extends Component {
  constructor(props) {
    super(props);
    let showPortfolio = null;

    let portfolioArr = Object.values(props.portfolioData) || [];

    console.log("portfolioArr", portfolioArr);

    /*
    if (portfolioArr.length > 0) {
      showPortfolio = portfolioArr[0].name;
    }
    */

    this.state = {
      percentage: "",
      newPortfolio: false,
      portfolioName: "",
      showPortfolio,
      shares: null,
      cost: null,
      date: null,
      newLot: false,
      showStock: null,
      datePickerValue: null,
      selectLot: null,
    };
  }

  componentDidUpdate(prevProps) {
    /*
    if (
      prevProps.portfolioData != this.props.portfolioData &&
      prevProps.portfolioData.length == 0
    ) {
      let firstPortfolio = Object.values(this.props.portfolioData)[0];
      this.setState({ showPortfolio: firstPortfolio.name });
    }
    */
  }

  extendedPortfolio = (showPortfolio) => {
    if (showPortfolio == this.state.showPortfolio) {
      showPortfolio = null;
    }
    this.setState({
      showPortfolio,
      newPortfolio: false,
      cost: null,
      shares: null,
      date: null,
      newLot: false,
      selectLot: null,
    });
  };

  showStock = (name) => {
    if (name == this.state.showStock) {
      name = null;
    }
    this.setState({
      showStock: name,
      cost: null,
      shares: null,
      date: null,
      newLot: false,
      selectLot: null,
    });
  };

  makeNewPortfolio = () => {
    this.setState({ newPortfolio: true, showPortfolio: "null" });
  };

  portfolioNameChange = (event) => {
    let portfolioName = event.target.value;
    this.setState({ portfolioName });
  };

  submitNewPortfolio = (event) => {
    event.preventDefault();
    let { portfolioName } = this.state;
    this.props.makePortfolio(portfolioName);
    this.setState({
      newPortfolio: false,
      portfolioName: "",
      showPortfolio: portfolioName,
    });
  };

  onLotSubmit = (event) => {
    event.preventDefault();
    let { cost, shares, datePickerValue, showStock, showPortfolio } =
      this.state;
    let date = new Date(datePickerValue).valueOf();
    if (!datePickerValue) {
      date = new Date().valueOf();
    }
    this.props.updatePortfolio(showStock, showPortfolio, shares, cost, date);
    this.setState({
      newLot: false,
      shares: null,
      cost: null,
      datePickerValue: null,
    });
  };

  onSharesChange = (event) => {
    let shares = event.target.value;
    this.setState({ shares });
  };

  onCostChange = (event) => {
    let cost = event.target.value;
    this.setState({ cost });
  };

  onDateChange = (event) => {
    let date = event.target.value;
    this.setState({ date });
  };

  addLotShow = () => {
    this.setState({ newLot: true, selectLot: null });
  };

  selectLot = (selectLot) => {
    this.setState({ selectLot });
  };

  toggleStream = (portfolio, stream = false) => {
    let newStream = !stream;
    console.log("portfolioArr", this.state.portfolioArr);
    let db = getDatabase();
    set(
      ref(
        db,
        "users/" + this.props.uid + "/portfolio/" + portfolio + "/stream"
      ),
      newStream
    );
  };

  removeLot = (lot, stock) => {
    let { showPortfolio, showStock } = this.state;
    let qwe = Object.entries(stock?.trades);
    let lotId = qwe[lot][0];
    console.log("removeLot", showStock, showPortfolio, lot, lotId);
    this.setState({ selectLot: null });

    let db = getDatabase();
    set(
      ref(
        db,
        "users/" +
          this.props.uid +
          "/portfolio/" +
          showPortfolio +
          "/stock/" +
          showStock +
          "/trades/" +
          lotId
      ),
      null
    );
  };

  render() {
    let {
      portfolioInput,
      percentage,
      showPortfolio,
      showStock,
      datePickerValue,
      selectLot,
    } = this.state;
    let {
      darkmode,
      isMobile,
      networkErr,
      portfolioData,
      stockData,
      cryptoData,
      preferences,
      activePortfolio,
      makePortfolioActive,
      removePortfolio,
      portfolioStocks,
      showFiltered,
    } = this.props;

    /*
    let friendsArr = [
      {
        name: "johny",
        id: 123,
        percentage: 100,
        color: "#1919B9",
        difference: 3,
      },
      {
        name: "robert",
        id: 314,
        percentage: 100,
        color: "#E18324",
        difference: 14,
      },
      {
        name: "raoul",
        id: 899,
        percentage: 100,
        color: "#B20000",
        difference: -4,
      },
    ];
    let activeFriend = 0;
    let showFriends = friendsArr.map((a, k) => {
      let changeColor = "#63BD3C";
      let showCaret = "fa fa-caret-up";
      if (activeFriend == k) {
        changeColor = "#63BD3C";
      }
      if (a.difference < 0) {
        changeColor = "#bd0101";
        showCaret = "fa fa-caret-down";
        if (activeFriend == k) {
          changeColor = "#ffbe9e";
        }
      }
      return (
        <div key={k} className={"favListBox"}>
          <button
            className={"favListItem"}
            style={{ width: 300 }}
            onClick={() => props.onPressStockFilter(a.id)}
          >
            <div
              className={"favListLeftFiltered"}
              style={{ alignItems: "center", marginRight: 40 }}
            >
              <div
                style={{
                  height: 24,
                  width: 24,
                  backgroundColor: a.color,
                  opacity: 0.8,
                  marginRight: 6,
                }}
              />
              <div className={"favListSymbol"}>{a.name}</div>
              <div className={"favListName"}>{a.id}</div>
            </div>
            <div className={"favListRight"}>
              <div
                className={`${showCaret} favItemCaret`}
                style={{ color: changeColor }}
              ></div>

              <div
                className={"favItemStockPrice"}
                style={{ color: changeColor }}
              >
                {a.difference.toFixed(2)}%
              </div>
            </div>
          </button>
        </div>
      );
    });
    */

    /*
    console.log(
      "stockData, cryptoData, portfolioData",
      stockData,
      cryptoData,
      portfolioData
    );
    */

    /*
    portfolioData = portfolioData.map((a) => {
      if (a.type == "stock") {
        return {
          ...stockData.filter((b) => b.symbol == a.name)[0],
          ...a,
        };
      }
      if (a.type == "crypto") {
        return {
          ...cryptoData.filter((b) => b.symbol == a.name)[0],
          ...a,
        };
      }
    });
    */

    let portfolioArr = Object.values(portfolioData);

    //// ehh date stuff

    var options = {
      timeZone: "America/New_York",
      hour12: false,
      hour: "2-digit",
      hourCycle: "h24",
    };
    let estDate = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
    });
    let d = new Date(estDate);
    let hour = new Date().toLocaleString("en-US", options);
    if (hour == "24") {
      hour = 0;
    }
    let day = d.getDay();
    let minute = d.getMinutes();

    let actDay = day;

    if (hour < 9 || (hour == 9 && minute < 30)) {
      actDay = day - 1;
    }

    if (
      day == 6 ||
      day == 0 ||
      (day == 1 && hour < 9) ||
      (day == 1 && hour == 9 && minute < 30)
    ) {
      actDay = 5;
    }

    let yesterday = actDay - 1;
    if (yesterday == 0) {
      yesterday = 5;
    }

    /////

    let allportChanges = portfolioStocks.map((a, k) => {
      //console.log("a", a);
      if (!a) return null;
      //console.log("a", a);
      // upDown difference
      let stockPrice = a.price?.toFixed(2);
      let yesterdayEod = 0;
      let upDown = "percentage"; //preferences.upDown || "percentage";
      let difference = 0;
      let difSymbol = "";
      if (a.series) {
        if (hour <= 9 || (hour == 9 && minute < 30)) {
          stockPrice = a.series[actDay] || stockPrice;
        }
        yesterdayEod = a.series[yesterday] || stockPrice;

        if (upDown == "percentage") {
          difference = ((stockPrice / yesterdayEod) * 100 - 100).toFixed(2);
          difSymbol = "%";
        } else {
          difference = (stockPrice - yesterdayEod).toFixed(2);
        }
      } else {
        difference = "X.XX";
      }
      return { difference, name: a.symbol, price: stockPrice, yesterdayEod };
    });

    allportChanges = allportChanges.filter((a) => a);

    let showPortfolios = portfolioArr.map((a, k) => {
      let stocks = Object.values(a?.stock || {}) || [];
      stocks = stocks.map((l, m) => ({
        ...l,
        changes: allportChanges.filter((q) => q.name == l.name)[0],
      }));

      //p("a", a);
      let allStocksValue = 0;
      let allStocksValueBefore = 0;

      //show stocks
      let showLots = stocks.map((b, p) => {
        let marketValue = 0;
        let totalShares = 0;
        let sumInitMarketValue = 0;

        let trades = Object.values(b?.trades || {}) || [];
        let showTrades = trades.map((c, j) => {
          //console.log("c", c);
          totalShares += Number(c.shares);

          let marketValue = (b.changes?.price * Number(c.shares)).toFixed(2);
          //allMarketValue += marketValue;
          let perDif = 1 + Number(b.changes?.difference) / 100;
          let valueBefore = marketValue / perDif;
          //allMarketValueBefore += valueBefore;
          let valueNowDif = (marketValue - valueBefore).toFixed(2);
          let initMarketValue = Number(c.shares) * c.cost;
          sumInitMarketValue += initMarketValue;
          let totalMarketChange = (marketValue - initMarketValue).toFixed(2);
          let totalPerChange = (
            (totalMarketChange / initMarketValue) *
            100
          ).toFixed(2);

          let totalPerColor = darkmode ? "#5fff5f" : "#0c870c";
          if (totalPerChange < 0) {
            totalPerColor = darkmode ? "#ef0101" : "#bd0101";
          }

          let todayDif = b.changes?.difference;
          let todayColor = darkmode ? "#5fff5f" : "#0c870c";
          if (todayDif < 0) {
            todayColor = darkmode ? "#ef0101" : "#bd0101";
          }

          //date format
          let fullDate = new Date(c.date);
          let dateFormatted =
            (fullDate.getMonth() > 8
              ? fullDate.getMonth() + 1
              : "0" + (fullDate.getMonth() + 1)) +
            "/" +
            (fullDate.getDate() > 9
              ? fullDate.getDate()
              : "0" + fullDate.getDate()) +
            "/" +
            fullDate.getFullYear().toString().substr(-2);

          return (
            <div
              onClick={() => this.selectLot(j)}
              key={j}
              className={"profileTextSub"}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
                height: 56, //38,
                backgroundColor: darkmode
                  ? selectLot == j
                    ? "#777"
                    : j % 2 == 1
                    ? "#272727"
                    : "#333"
                  : selectLot == j
                  ? "#777"
                  : j % 2 == 1
                  ? "#fbf7f5"
                  : "#eee",
                paddingRight: 8,
                cursor: "pointer",
                color: selectLot == j ? "#FFF" : darkmode ? "#ccc" : "#000",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginLeft: 8 }}>{dateFormatted}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        width: 44,
                        textAlign: "right",
                        marginRight: 4,
                      }}
                    >
                      {c.shares}
                    </div>
                    <div
                      style={{
                        opacity: darkmode ? 1 : 0.6,
                        marginRight: 1,
                        fontSize: 13,
                        marginTop: -0.5,
                      }}
                    >
                      @
                    </div>
                    <div>{`$${c.cost}`}</div>
                  </div>
                  <div style={{ fontSize: 13 }}>{`mv: $${marketValue}`}</div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{ fontWeight: 400, opacity: 0.8, color: todayColor }}
                  >
                    {todayDif}%
                  </div>
                  <div style={{ fontSize: 13 }}>{`$${valueNowDif}`}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    marginLeft: 12,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 400,
                      opacity: 0.8,
                      color: totalPerColor,
                    }}
                  >
                    {`${totalPerChange}%`}
                  </div>
                  <div style={{ fontSize: 13 }}>{`$${totalMarketChange}`}</div>
                </div>
              </div>
              {selectLot == j && (
                <button
                  onClick={() => this.removeLot(j, b)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: -12,
                  }}
                >
                  <img
                    src={closeXWhite}
                    className={"removeButtonXPortfolioLot"}
                  />
                </button>
              )}
            </div>
          );
        });

        let averageIn = 0;
        if (totalShares) {
          averageIn = (sumInitMarketValue / totalShares).toFixed(2);
        }

        let totalValue = b.changes?.price * totalShares;
        let totalValueYesterday = b.changes?.yesterdayEod * totalShares;

        let howMuchWentUp = (totalValue - totalValueYesterday).toFixed(2);

        /*
        let howMuchWentUp = (
          totalValue *
          (b.changes?.difference / 100)
        ).toFixed(2);
        */

        let totalValueBefore = totalValue - howMuchWentUp;
        allStocksValue += totalValue ? totalValue : 0;
        allStocksValueBefore += totalValueBefore ? totalValueBefore : 0;

        let howMuchWentUpTotal = (totalValue - sumInitMarketValue).toFixed(2);
        let totalStockPerChange = (
          (totalValue / sumInitMarketValue) * 100 -
          100
        ).toFixed(2);
        let symbolTotal = "";
        let symbolDaily = "";
        if (Number(b?.changes?.difference) > 0) {
          symbolDaily = "+";
        }
        if (Number(totalStockPerChange) > 0) {
          symbolTotal = "+";
        }
        if (
          Number(totalStockPerChange) > 999 ||
          Number(totalStockPerChange) < -999
        ) {
          totalStockPerChange = Number(totalStockPerChange).toFixed(0);
        }

        let totalStockPerColor = darkmode ? "#5fff5f" : "#0c870c";
        if (totalStockPerChange < 0) {
          totalStockPerColor = darkmode ? "#ef0101" : "#bd0101";
        }
        if (totalStockPerChange == "NaN") {
          totalStockPerChange = 0;
          totalStockPerColor = "#ccc";
        }

        let dailyPerChange = b?.changes?.difference;
        let dailyPerColor = darkmode ? "#5fff5f" : "#0c870c";
        if (dailyPerChange < 0) {
          dailyPerColor = darkmode ? "#ef0101" : "#bd0101";
        }

        return (
          <div
            key={p}
            style={{
              marginLeft: 10,
              marginRight: 10,
              borderRadius: 1,
              border: darkmode ? "1px solid #444" : "1px solid #bbb",
              display: "flex",
              flexDirection: "column",
              marginBottom: 6,
              backgroundColor: darkmode ? "#272727" : "#fbf7f5",
              //height: 64,
              //border: showStock == b.name ? "1px solid #555" : "1px solid #bbb",
            }}
          >
            <div
              onClick={() => this.showStock(b.name)}
              style={{
                display: "flex",
                alignSelf: "stretch",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignSelf: "stretch",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                <div
                  className={"graphTextName"}
                  style={{
                    marginLeft: 8,
                    opacity: 0.7,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {b.name}

                  <div
                    className={"graphTextName"}
                    style={{
                      marginLeft: 4,
                      opacity: 0.9,
                      fontWeight: 300,
                    }}
                  >
                    ${b?.changes?.price}
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className={"graphTextName"}
                    style={{ marginRight: 2, opacity: 0.9, fontWeight: 300 }}
                  >
                    {"1d:"}
                  </div>
                  <div
                    className={"graphTextName"}
                    style={{
                      opacity: 0.9,
                      fontWeight: 300,
                      width: 78,
                      textAlign: "right",
                    }}
                  >
                    {`$${howMuchWentUp}`}
                  </div>
                  <div
                    className={"graphTextName"}
                    style={{
                      marginRight: 10,
                      opacity: 0.8,
                      fontWeight: 400,
                      width: 64,
                      textAlign: "right",
                      color: dailyPerColor,
                    }}
                  >
                    {symbolDaily}
                    {dailyPerChange}%
                  </div>
                </div>
              </div>
              {/* 2nd row */}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignSelf: "stretch",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop: 4,
                  marginBottom: 6,
                }}
              >
                <div
                  className={"graphTextName"}
                  style={{
                    marginLeft: 8,
                    opacity: 0.7,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      opacity: 1,
                      fontSize: 15,
                      fontWeight: 300,
                    }}
                  >{` [${totalShares}`}</span>
                  <span
                    style={{
                      marginLeft: 2,
                      opacity: 0.7,
                      fontWeight: 300,
                      fontSize: 12,
                      marginTop: -0,
                    }}
                  >
                    @
                  </span>
                  <span
                    style={{
                      marginLeft: 1,
                      opacity: 1,
                      fontWeight: 300,
                      marginTop: 0,
                    }}
                  >
                    {`$${averageIn}]`}
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className={"graphTextName"}
                    style={{ marginRight: 2, opacity: 0.9, fontWeight: 300 }}
                  >
                    {"total:"}
                  </div>
                  <div
                    className={"graphTextName"}
                    style={{
                      opacity: 0.9,
                      fontWeight: 300,
                      width: 78,
                      textAlign: "right",
                    }}
                  >
                    {`$${howMuchWentUpTotal}`}
                  </div>
                  <div
                    className={"graphTextName"}
                    style={{
                      marginRight: 10,
                      opacity: 0.8,
                      fontWeight: 400,
                      width: 64,
                      textAlign: "right",
                      color: totalStockPerColor,
                    }}
                  >
                    {symbolTotal}
                    {totalStockPerChange}%
                  </div>
                </div>
              </div>
            </div>
            {this.state.showStock == b.name && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                {trades.length > 0 && (
                  <div
                    className={"profileTextSub"}
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      height: 38,
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingRight: 8,
                      border: darkmode ? "0px solid #555" : "0px solid #ccc",
                      borderTopWidth: 1,
                      marginTop: 4,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 10,
                        textDecorationLine: "underline",
                      }}
                    >
                      date
                    </div>
                    <div style={{ textDecorationLine: "underline" }}>
                      shares
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{ textDecorationLine: "underline" }}
                      >{`1d`}</div>
                      <div
                        style={{
                          marginRight: 2,
                          marginLeft: 36,
                          textDecorationLine: "underline",
                        }}
                      >{`total`}</div>
                    </div>
                  </div>
                )}
                {showTrades}
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    alignSelf: "stretch",
                    height: 48,
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  {this.state.newLot ? (
                    <form
                      onSubmit={(e) => this.onLotSubmit(e, a.name)}
                      style={{
                        display: "flex",
                        alignSelf: "stretch",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginLeft: 4,
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <DatePicker
                          onChange={(value) =>
                            this.setState({ datePickerValue: value })
                          }
                          value={datePickerValue}
                          monthPlaceholder={"mm"}
                          dayPlaceholder={"dd"}
                          yearPlaceholder={"yyyy"}
                        />
                        <input
                          type="text"
                          className={
                            darkmode ? "portfolioSharesDark" : "portfolioShares"
                          }
                          value={this.state.shares}
                          onChange={this.onSharesChange}
                          placeholder={"shares"}
                        />
                        <input
                          type="text"
                          className={
                            darkmode ? "portfolioCostDark" : "portfolioCost"
                          }
                          value={this.state.cost}
                          onChange={this.onCostChange}
                          placeholder={"cost/share"}
                        />
                        <div className={"searchSubmitButtonBox"}>
                          <input
                            type="submit"
                            className={
                              darkmode
                                ? "searchSubmitButtonDark"
                                : "searchSubmitButton"
                            }
                            value=""
                          />
                          <div
                            className={
                              darkmode ? "submitPlusFontDark" : "submitPlusFont"
                            }
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignSelf: "stretch",
                        flex: 1,
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          this.addLotShow();
                        }}
                        className={"profileTextSub"}
                        style={{
                          borderRadius: 4,
                          border: "1px solid #bbb",
                          marginLeft: 8,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingRight: 12,
                          opacity: 0.8,
                          cursor: "pointer",
                          height: 34,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 28,
                            opacity: 0.6,
                            marginLeft: 8,
                            marginRight: 6,
                            marginTop: -2,
                          }}
                        >
                          +
                        </span>
                        Add Lot
                      </div>
                      {/*}
                      <div
                        onClick={() => {
                          this.remoteLot();
                        }}
                        className={"profileTextSub"}
                        style={{
                          borderRadius: 4,
                          border: "1px solid #bbb",
                          height: 34,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingRight: 10,
                          opacity: 0.8,
                          cursor: "pointer",
                          marginRight: 8,
                        }}
                      >
                        <span
                          className={"fa fa-trash"}
                          style={{
                            fontSize: 15,
                            opacity: 0.3,
                            marginLeft: 10,
                            marginRight: 6,
                          }}
                        ></span>
                        Delete
                      </div>
                      */}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      });

      let changeColor = darkmode ? "#5fff5f" : "#0c870c";
      let allStockValueDif = (allStocksValue - allStocksValueBefore).toFixed(2);
      let allStockPerDif = 0;
      if (allStocksValue) {
        allStockPerDif = (
          (allStocksValue / allStocksValueBefore) * 100 -
          100
        ).toFixed(2);
      }
      if (allStockPerDif < 0) {
        changeColor = darkmode ? "#ef0101" : "#bd0101";
      }

      //showPortfolio == a.name ? "noHighlight boxShadow" : "noHighlight"

      let filteredList = showFiltered.map((f, e) => {
        return (
          <div key={e} className={"favListBox"}>
            <button
              className={
                darkmode
                  ? "favListItemPortfolioFilterDark"
                  : "favListItemPortfolioFilter"
              }
              onClick={() =>
                this.props.onPressStockFilter(f.symbol, a.name, true)
              }
            >
              <div className={"favListLeftFiltered"}>
                <div className={"favListSymbol"}>{f.symbol}</div>
                <div className={darkmode ? "favListNameDark" : "favListName"}>
                  {f.name}
                </div>
              </div>
              <div className={"favListRight"}>
                <div className={"searchSubmitButtonBoxFilter"}>
                  <div className={"submitPlusFontFilter"}>+</div>
                </div>
              </div>
            </button>
          </div>
        );
      });

      return (
        <div
          key={k}
          className={
            darkmode ? "noHighlight prefBoxPortDark" : "noHighlight prefBoxPort"
          }
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
            marginBottom: showPortfolio == a.name ? 12 : 8,
            paddingBottom: showPortfolio == a.name ? 3 : 0,
            width: isMobile ? 348 : "auto",
          }}
        >
          <div
            onClick={(e) => {
              if (e.target !== e.currentTarget) return;
              this.extendedPortfolio(a.name);
            }}
            style={{
              display: "flex",
              alignSelf: "stretch",
              height: 42,
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 2,
              cursor: "pointer",
              justifyContent: "space-between",
              paddingTop: 0,
            }}
          >
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <RssButton
                onClick={() => this.toggleStream(a.name, a.stream)}
                activeRow={true}
                inactive={!a.stream}
                darkmode={darkmode}
              />

              <div
                onClick={(e) => {
                  this.extendedPortfolio(a.name);
                }}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    fontSize: 10,
                    opacity: 0.3,
                    marginTop: 4,
                  }}
                >
                  {showPortfolio == a.name ? "▼" : "▶"}
                </div>
                <div
                  className={"portfolioName"}
                  style={{
                    marginLeft: 8,
                    color:
                      showPortfolio && showPortfolio != a.name
                        ? "#999"
                        : darkmode
                        ? "#ddd"
                        : "#000",
                  }}
                >
                  {a.name}
                </div>
              </div>
            </div>
            {showPortfolio != a.name && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  className={"graphTextName"}
                  style={{ marginRight: 10, marginTop: 1, fontWeight: 300 }}
                >{`$${allStockValueDif}`}</div>
                <div
                  className={"graphTextName"}
                  style={{ marginRight: 10, marginTop: 1, color: changeColor }}
                >{`${allStockPerDif}%`}</div>
              </div>
            )}

            {showPortfolio == a.name && (
              <button onClick={() => this.props.removePortfolio(a.name)}>
                <img
                  src={darkmode ? closeXDark : closeX}
                  className={"removeButtonXPortfolio"}
                />
              </button>
            )}
          </div>
          {showPortfolio == a.name && (
            <div
              style={{ paddingBottom: 0, alignSelf: "stretch", marginTop: 4 }}
            >
              {showLots}
              {true && (
                <div
                  style={{
                    display: "flex",
                    alignSelf: "stretch",
                    marginLeft: 10,
                    marginBottom: 2,
                    flexDirection: "column",
                  }}
                >
                  <form
                    onSubmit={(e) => this.props.onPortfolioSubmit(e, a.name)}
                    style={{
                      display: "flex",
                      alignSelf: "stretch",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginLeft: 6,
                      marginTop: 2,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        type="text"
                        className={
                          darkmode
                            ? "searchSubmitBoxPortfolioDark"
                            : "searchSubmitBoxPortfolio"
                        }
                        value={this.props.portfolioInput}
                        onChange={this.props.onPortfolioInput}
                        placeholder={"STOCK/ETF"}
                        style={{
                          backgroundColor: darkmode ? "#272727" : "#fff",
                          border: darkmode
                            ? "1px solid #777"
                            : "1px solid #bbb",
                        }}
                      />
                      <div className={"searchSubmitButtonBox"}>
                        <input
                          type="submit"
                          className={
                            darkmode
                              ? "searchSubmitButtonDark"
                              : "searchSubmitButton"
                          }
                          value=""
                        />
                        <div
                          className={
                            darkmode ? "submitPlusFontDark" : "submitPlusFont"
                          }
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </form>
                  {networkErr && (
                    <div className={"networkErrPortfolio"}>{networkErr}</div>
                  )}
                  <div style={{ marginTop: 4 }}>
                    {showFiltered.length > 0 && filteredList}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
    return (
      <div style={{ alignSelf: "stretch", minWidth: 346 }}>
        {showPortfolios}
        <div
          onClick={() => this.makeNewPortfolio()}
          style={{
            display: "flex",
            flexDirection: "column",
            border: darkmode ? "1px solid #555" : "1px solid #ccc",
            alignSelf: "stretch",
            //new portfolio click
            borderRadius: 2,
          }}
        >
          <div
            className={"noHighlight"}
            style={{
              display: "flex",
              alignSelf: "stretch",
              height: 42,
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              marginTop: -1,
            }}
          >
            <div
              style={{
                marginLeft: 10,
                fontSize: 20,
                marginTop: -1,
                opacity: 0.6,
                color: showPortfolio ? "#777" : darkmode ? "#ccc" : "#000",
              }}
            >
              +
            </div>
            <div
              className={"portfolioNameNew"}
              style={{
                marginLeft: 7,
                color: showPortfolio ? "#999" : darkmode ? "#ccc" : "#000",
              }}
            >
              New Portfolio
            </div>
          </div>
          {this.state.newPortfolio && (
            <form
              onSubmit={this.submitNewPortfolio}
              style={{
                display: "flex",
                alignSelf: "stretch",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: 16,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  autoFocus
                  type="text"
                  className={
                    darkmode
                      ? "searchSubmitBoxPortfolioDark"
                      : "searchSubmitBoxPortfolio"
                  }
                  value={this.state.portfolioName}
                  onChange={this.portfolioNameChange}
                  placeholder={"Name"}
                />
                <div className={"searchSubmitButtonBox"}>
                  <input
                    type="submit"
                    className={
                      darkmode ? "searchSubmitButtonDark" : "searchSubmitButton"
                    }
                    value=""
                  />
                  <div
                    className={
                      darkmode ? "submitPlusFontDark" : "submitPlusFont"
                    }
                  >
                    +
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

/*
<form
  onSubmit={this.props.onPortfolioSubmitCrypto}
  style={{
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 6,
    marginLeft: 6,
    marginBottom: 10,
  }}
>
  <div style={{ display: "flex", flexDirection: "row" }}>
    <input
      type="text"
      className={"searchSubmitBoxPortfolio"}
      value={this.props.portfolioInputCrypto}
      onChange={this.props.onPortfolioInputCrypto}
      placeholder={"CRYPTO"}
    />
    <div className={"searchSubmitButtonBox"}>
      <input
        type="submit"
        className={"searchSubmitButton"}
        value=""
      />
      <div className={"submitPlusFont"}>+</div>
    </div>
  </div>
</form>
*/

export default ListPortfolio;
