import React, { useState, useEffect } from "react";
import Axios from "axios";
import ReactJson from "react-json-view";
import "react-dropdown/style.css";

let Ping = ({ activeDeviceRow, uid, isMobile }) => {
  let [data, setData] = useState({});
  useEffect(() => {
    req();
  }, [activeDeviceRow]);

  let req = async () => {
    if (!activeDeviceRow) return;
    Axios.get("https://quotron.co/psudoBox/" + activeDeviceRow)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("ping err", err);
      });
  };

  if (!activeDeviceRow) return null;
  return (
    <>
      <div className={"settingsTitle"} style={{ marginTop: 10 }}>
        ping:
      </div>
      <div
        style={{
          border: "1px solid #777",
          paddingLeft: 6,
          maxWidth: isMobile ? 352 : 708,
        }}
      >
        <ReactJson
          displayDataTypes={false}
          displayArrayKey={false}
          displayObjectSize={false}
          style={{
            marginTop: 20,
            opacity: data != "" ? 1 : 0.5,
            fontSize: 14,
          }}
          src={data}
        />
      </div>
    </>
  );
};
export default Ping;

/*
<div className={"prefBox"} style={{ maxWidth: 300, marginTop: 40 }}>
  <div
    className={
      isMobile ? "deviceRegisterBoxMobile" : "deviceRegisterBox"
    }
    style={{ margin: 20 }}
    onClick={() => this.sendBoxReq("2201-774-991-712-62")}
  >
    send box req
  </div>
  <div>
    GET
    data.quotron.co:3000/stream/default?device_id=2201-774-991-712-62
  </div>
  <ReactJson
    style={{
      marginTop: 20,
      opacity: this.state.boxRes?.data != "" ? 1 : 0.5,
    }}
    src={this.state.boxRes}
  />
</div>
*/
