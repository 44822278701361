import React, { useState } from "react";
import Select from "react-select";

let TimezoneSelect = ({ darkmode, preferences, setTimezone }) => {
  const options = [
    { value: "PST", label: "PST" },
    { value: "MST", label: "MST" },
    { value: "CST", label: "CST" },
    { value: "EST", label: "EST" },
  ];
  let defaultValue = options[3];
  if (preferences?.timezone == "PST") {
    defaultValue = options[0];
  }
  if (preferences?.timezone == "MST") {
    defaultValue = options[1];
  }
  if (preferences?.timezone == "CST") {
    defaultValue = options[2];
  }
  let darkColor = darkmode ? "#272727" : "#777";
  return (
    <div>
      <Select
        options={options}
        styles={{
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? darkColor : darkColor,
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? "#444" : darkColor,
            color: "#fff",
          }),
          valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? "#444" : darkColor,
          }),
          singleValue: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? "#444" : darkColor,
            color: darkmode ? "#bbb" : "#fff",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: darkColor,
            color: darkmode ? "#ccc" : "#fff",
            borderColor: "#777",
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: "#999",
          }),
        }}
        onChange={(value) => setTimezone(value)}
        value={defaultValue}
      />
    </div>
  );
};

export default TimezoneSelect;
