/* eslint-env browser */
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory,
  Route,
} from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import Home from "./Home";
import "./styles.css";
import ico from "./imgs/ico.png";

const App = () => (
  <Router>
    <Route path="/:dir">
      <HomeWithParams />
    </Route>
    <Route exact path="/">
      <HomeWithParams />
    </Route>
  </Router>
);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function HomeWithParams() {
  let query = useQuery();
  let newDevice = query.get("newDevice");
  let oobCode = query.get("oobCode");
  let mode = query.get("mode");
  let invite = query.get("invite");
  let phoneNum = query.get("phoneNum");
  let continueUrl = query.get("continueUrl");
  let step = query.get("step");

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <CookiesProvider>
      <Home
        newDevice={newDevice}
        isMobile={isMobile}
        screenHeight={height}
        screenWidth={width}
        oobCode={oobCode}
        invite={invite}
        mode={mode}
        continueUrl={continueUrl}
        phoneNum={phoneNum}
        step={step}
      />
    </CookiesProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("app"));
module.hot.accept();
