import React, { useState, useEffect } from "react";
import Axios from "axios";

let Groups = ({ uid }) => {
  let [data, setData] = useState({});
  let [groupObj, setGroupObj] = useState({});
  let [type, setType] = useState(null);
  let [added, setAdded] = useState([]);

  useEffect(() => {
    getGroups();
  }, []);

  let getGroups = async () => {
    var options = {
      method: "POST",
      url: "https://quotron.co/getGroups/",
      data: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      if (res) {
        let data = res.data.groups?.filter((a) => a.type == "groupData")[0];
        setGroupObj(data);
      }
    });
  };

  console.log("groupObj", groupObj);

  let showAdded = added?.map((a, k) => {
    return <div style={local.idRow}>{`num`}</div>;
  });

  return (
    <div
      style={{
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          height: 300,
          overflow: "scroll",
          border: "0px solid #888",
          alignSelf: "stretch",
          flex: 1,
        }}
      >
        <div style={local.idRow}>{`+`}</div>
        {showAdded}
      </div>
      <div
        style={{
          height: 300,
          overflow: "scroll",
          border: "0px solid #888",
          borderLeftWidth: 1,
        }}
      >
        <div
          onClick={() => setType("sleep")}
          style={type == "sleep" ? local.typeRowActive : local.typeRow}
        >{`sleep`}</div>
        <div
          onClick={() => setType("upcoming")}
          style={type == "upcoming" ? local.typeRowActive : local.typeRow}
        >{`upcoming`}</div>
        <div
          onClick={() => setType("store")}
          style={type == "store" ? local.typeRowActive : local.typeRow}
        >{`store`}</div>
      </div>
    </div>
  );
};
export default Groups;

let local = {
  idRow: {
    alignSelf: "stretch",
    height: 30,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 6,
    paddingLeft: 6,
  },
  typeRow: {
    width: 100,
    height: 30,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 3,
    paddingLeft: 3,
    cursor: "pointer",

  },
  typeRowActive: {
    width: 100,
    height: 30,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 3,
    paddingLeft: 3,
    color: "#FFF",
    backgroundColor: "#888",
    cursor: "pointer",
  },
};
