import React from "react";
let Blank = ({ screenHeight }) => {
  return (
    <div
      style={{
        height: screenHeight,
        alignSelf: "stretch",
        backgroundColor: "#000",
      }}
    ></div>
  );
};
export default Blank;
