import React, { useState } from "react";
import ReactSlider from "react-slider";

let BrightnessSlider = ({ darkmode, setValue, value }) => {
  return (
    <div style={{ display: "flex", width: 200, position: "relative" }}>
      <ReactSlider
        className="horizontal-slider"
        marks
        markClassName="example-mark"
        defaultValue={value}
        min={1}
        max={9}
        thumbClassName={darkmode ? "example-thumb" : "example-thumb-light"}
        trackClassName="example-track"
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        onAfterChange={(result) => setValue(result)}
      />
    </div>
  );
};

export default BrightnessSlider;
