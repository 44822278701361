import React from "react";
import rssWhite from "../../imgs/rssWhite3.png";

function RssButton(props) {
  let {
    onClick,
    activeRow,
    darkmode,
    inactive,
    marginLeft = 2,
    marginRight = 2,
    offOpacity = 0.2,
  } = props;
  return (
    <div
      onClick={onClick}
      style={{
        height: 36,
        width: activeRow ? 36 : 20,
        borderRadius: 2,
        //borderTopLeftRadius: 0,
        backgroundColor: inactive
          ? activeRow
            ? darkmode
              ? "#2d2d2d"
              : "#ccc"
            : darkmode
            ? "#272727"
            : "#ddd"
          : "#5e7cce",
        //display: "none",
        marginRight,
        marginLeft,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border:
          !darkmode && !inactive && activeRow
            ? "0.5px solid #ccc"
            : "0px solid #aaa",
      }}
    >
      {activeRow && (
        <img
          src={rssWhite}
          style={{
            height: 22,
            width: 22,
            opacity: inactive
              ? darkmode
                ? offOpacity
                : 0.6
              : darkmode
              ? 0.5
              : 1,
          }}
        />
      )}
    </div>
  );
}

export default RssButton;
