import React from "react";
function Profile(props) {
  return (
    <>
      {props.isMobile && <div className={"settingsTitle"}>Profile:</div>}
      <div className={"prefBox"}>
        <div className={"profileBox"} />
      </div>
    </>
  );
}
export default Profile;
