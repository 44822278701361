import React, { useState, useEffect } from "react";
import Axios from "axios";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

let Ota = ({ uid, isMobile, userDevices = [], getUsers }) => {
  let [deviceOTA, setDeviceOTA] = useState("");
  let [deviceOTA2, setDeviceOTA2] = useState("");
  let [deviceOTAList, setDeviceOTAList] = useState("");
  let [updateOps, setUpdateOps] = useState([
    "QuotronLEDController.20A.bin",
    "quotron_2_1_a.bin",
    "quotron_2_1_b.bin",
  ]);

  let [urlOTA, setUrlOta] = useState(updateOps[0]);
  let [urlOTAList, setUrlOtaList] = useState(updateOps[0]);
  let [mongo, setMongo] = useState([]);
  let [targetName, setTargetName] = useState("");
  let [boxData, setBoxData] = useState([]);

  useEffect(() => {
    getBins();
    getUpdateList();
    const intervalId = setInterval(getUpdateList, 15000); // 15 seconds
    return () => clearInterval(intervalId);
  }, []);

  let getBins = () => {
    var options = {
      method: "GET",
      url: "https://quotron.co/updateBins/" + uid,
      params: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      if (res) {
        let binData = res.data?.filter((a) => a.includes(".bin"));
        setUpdateOps(binData);
        setUrlOta(binData[0]);
        setUrlOtaList(binData[0]);
      }
    });
  };

  let getUpdateList = () => {
    var options = {
      method: "GET",
      url: "https://quotron.co/admin/otaList/" + uid,
      params: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      setMongo(res.data?.mongo);
      getUsers();
      getBoxData();
    });
  };

  let getBoxData = () => {
    var options = {
      method: "GET",
      url: "https://quotron.co/admin/boxData/" + uid,
      params: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      setBoxData(res.data?.mongo);
    });
  };

  let deleteList = (target) => {
    var options = {
      method: "POST",
      url: "https://quotron.co/admin/otaDelete/",
      data: {
        uid,
        target,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      console.log("delete List", res.data);
      getUpdateList();
    });
  };

  console.log("mongo", mongo);

  let onDeviceOTA = (event) => {
    let newVal = event.target.value;
    setDeviceOTA(newVal);
  };

  let onDeviceOTA2 = (event) => {
    let deviceOTA2 = event.target.value;
    setDeviceOTA2(deviceOTA2);
  };

  let onDeviceOTAList = (event) => {
    let deviceOTAList = event.target.value;
    setDeviceOTAList(deviceOTAList);
  };

  let onUrlOTA = (event) => {
    let newVal = event.target.value;
    setUrlOta(newVal);
  };

  let deviceOTARevert = (event) => {
    event.preventDefault();

    var options = {
      method: "POST",
      url: "https://quotron.co/otaRevert/",
      data: {
        uid,
        deviceOTA: deviceOTA2,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.request(options)
      .then((res) => {
        setDeviceOTA2("");
      })
      .catch((err) => {
        console.log("device OTA err", err);
      });
  };

  const getDateDiff = (lastSeen) => {
    if (lastSeen == "") return "";
    const now = Date.now();
    const diff = now - lastSeen;

    // Convert diff to a human-readable format
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} days`;
    if (hours > 0) return `${hours} hours`;
    if (minutes > 0) return `${minutes} min`;
    return `${seconds} sec`;
  };

  let newList = async (target = urlOTAList) => {
    var options = {
      method: "POST",
      url: "https://quotron.co/admin/otaList",
      data: {
        uid,
        box: deviceOTAList,
        target,
        targetName,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("new list", options);
    Axios.request(options)
      .then((res) => {
        //  console.log("newList", res);
        setUrlOtaList("");
        getUpdateList();
        setDeviceOTAList("");
        setRow(null);
      })
      .catch((err) => {
        //  console.log("newList", err);
      });
  };

  let deviceOTASend = (event) => {
    event.preventDefault();
    let fullURL = "http://quotron.co/updates/" + urlOTA;

    deviceOTA = deviceOTA?.replaceAll(" ", "");

    var options = {
      method: "POST",
      url: "https://quotron.co/otaSend/",
      data: {
        uid,
        deviceOTA,
        urlOTA: fullURL,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.request(options)
      .then((res) => {
        setDeviceOTA("");
        setUrlOta("");
      })
      .catch((err) => {
        console.log("device OTA err", err);
      });
  };

  let selectUpdateDrop = (updateDrop) => {
    setUrlOta(updateDrop.value);
  };
  let selectUpdateDropList = (updateDrop) => {
    setUrlOtaList(updateDrop.value);
  };

  userDevices = userDevices.map((a) => {
    let lastSeen = boxData?.filter((b) => b.uid == a.id)[0]?.lastSeen || "";
    return { ...a, lastSeen };
  });

  let validDevice = userDevices?.filter((a) => a?.id == deviceOTA)[0];
  let validDeviceList = userDevices?.filter((a) => a?.id == deviceOTAList)[0];

  let multiList = mongo?.map((a, k) => {
    let showList = a?.list?.map((b, p) => {
      let bDev = userDevices?.filter((c) => c.id == b)[0];
      let bPanels = bDev?.panels || "";
      let bVer = bDev?.version || "";
      let lastSeen = bDev?.lastSeen || "";

      let isDone = a.done?.includes(b);
      let isCorrect = a.targetName == bVer;

      return (
        <div
          style={{
            alignSelf: "stretch",
            border: "1px solid #ccc",
            borderBottomWidth: 0,
            paddingLeft: 3,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: isCorrect ? "#a6f1cc" : "#fbf7f5",
          }}
        >
          {b}
          <div style={{ width: 50 }}>{isDone && `sent`}</div>
          <div>{bPanels}</div>
          <div>{bVer}</div>
          <div>{getDateDiff(lastSeen)}</div>
        </div>
      );
    });
    return (
      <div className={"profileTextSub"} style={local.otaL}>
        <div style={local.otaLHeader}>
          <div>{`~`}</div>
          <div>{`${a?.targetName}`}</div>
        </div>
        <div style={local.otaLHeader}>
          <div>{`${a?.target}`}</div>
          <div
            onClick={() => deleteList(a?.target)}
            style={{
              height: 30,
              width: 80,
              borderRadius: 4,
              border: "1px solid #888",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              alignSelf: "flex-end",
            }}
          >{`clear`}</div>
        </div>
        {showList}

        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "row",
            marginTop: 4,
            alignItems: "center",
            marginTop: 20,
          }}
        >
          {`+`}
          <input
            type="text"
            className={"portfolioShares"}
            style={{ minWidth: 240, marginLeft: 10 }}
            value={deviceOTAList}
            onChange={onDeviceOTAList}
            placeholder={"0000-000-000-000-000"}
          />
          {validDeviceList?.id && (
            <>
              <div
                className={"profileTextSub"}
                onClick={() => newList(a.target)}
                style={local.submitShort}
              >
                <div>{`+`}</div>
              </div>
              <div
                style={{ marginLeft: 10 }}
              >{`${validDeviceList?.panels}`}</div>
              <div
                style={{ marginLeft: 10 }}
              >{`${validDeviceList?.version}`}</div>
            </>
          )}
        </div>
      </div>
    );
  });

  let [row, setRow] = useState(null);
  let [table, setTable] = useState(false);
  let [panels, setPanels] = useState(null);
  let [ver, setVer] = useState(null);
  let [filterSeen, setFilterSeen] = useState(false);

  let clickDeviceRow = (a) => {
    let nextDeviceRow = a.id;
    if (row == a.id) {
      nextDeviceRow = null;
    }
    setRow(nextDeviceRow);
    setDeviceOTAList(nextDeviceRow);
  };

  if (panels) {
    userDevices = userDevices.filter((a) => a.panels == panels);
  }

  if (ver) {
    userDevices = userDevices.filter((a) => a.version == ver);
  }

  if (filterSeen) {
    userDevices = userDevices.sort((a, b) => {
      if (!a.lastSeen) return 1; // a should come after b
      if (!b.lastSeen) return -1; // b should come after a
      return b.lastSeen - a.lastSeen;
    });
  }

  let showDevices = userDevices?.map((a, k) => {
    if (!a) return null;

    return (
      <div
        key={k}
        className={"flexRow profileTextSub"}
        style={{ marginTop: -1 }}
        onClick={() => clickDeviceRow(a)}
        style={{
          backgroundColor: row == a.id ? "#555" : "#fbf7f5",
          color: row == a.id ? "#FFF" : "#000",
        }}
      >
        <div
          className={"flexRow"}
          style={{
            minWidth: 190,
            height: 33,
            border: "1px solid #ccc",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {a.id}
        </div>
        <div
          className={"flexRow"}
          style={{
            minWidth: 40,
            height: 33,
            border: "1px solid #ccc",
            borderLeftWidth: 0,
            alignItems: "center",
          }}
        >
          {a.panels || ""}
        </div>
        <div
          className={"flexRow"}
          style={{
            minWidth: 60,
            height: 33,
            border: "1px solid #ccc",
            borderLeftWidth: 0,
            alignItems: "center",
          }}
        >
          {a.version || ""}
        </div>
        <div
          className={"flexRow"}
          style={{
            minWidth: 220,
            height: 33,
            border: "1px solid #ccc",
            borderLeftWidth: 0,
            alignItems: "center",
          }}
        >
          {a.email || ""}
        </div>
        <div
          className={"flexRow"}
          style={{
            minWidth: 80,
            height: 33,
            border: "1px solid #ccc",
            borderLeftWidth: 0,
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {getDateDiff(a.lastSeen || "")}
        </div>
        <div
          className={"flexRow"}
          style={{
            minWidth: 300,
            height: 33,
            border: "1px solid #ccc",
            borderLeftWidth: 0,
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {a.account?.uid || ""}
        </div>
      </div>
    );
  });

  return (
    <div
      style={{
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        width: isMobile ? 350 : 700,
      }}
    >
      <div className={"profileTextSub"} style={{ alignSelf: "flex-start" }}>
        OTA Update
      </div>
      <div
        className={"profileTextSub"}
        style={{
          display: "flex",
          alignSelf: "stretch",
          flexDirection: "column",
          border: "1px solid #ccc",
          padding: 4,
          paddingTop: 10,
          paddingBottom: 6,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <input
            type="text"
            className={"portfolioShares"}
            style={{ minWidth: 240, marginBottom: 8 }}
            value={deviceOTA}
            onChange={onDeviceOTA}
            placeholder={"0000-000-000-000-000"}
          />
          <Dropdown
            options={updateOps}
            onChange={selectUpdateDrop}
            value={urlOTA}
            placeholder="12345.bin"
          />
        </div>

        {validDevice?.id ? (
          <div
            className={"profileTextSub"}
            onClick={(e) => deviceOTASend(e)}
            style={{
              border: "1px solid #888",
              borderRadius: 2,
              width: 100,
              height: 36,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 8,
              backgroundColor: "#888",
              color: "#fff",
              fontSize: 17,
            }}
          >
            <div>update</div>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div
        className={"profileTextSub"}
        style={{ alignSelf: "flex-start", marginTop: 20 }}
      >
        OTA Rollback
      </div>
      <div
        style={{
          display: "flex",
          alignSelf: "stretch",
          flexDirection: "column",
          border: "1px solid #ccc",
          padding: 4,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <form
          onSubmit={(e) => deviceOTARevert(e)}
          style={{
            display: "flex",
            alignSelf: "stretch",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            className={"portfolioShares"}
            style={{ minWidth: 240 }}
            value={deviceOTA2}
            onChange={onDeviceOTA2}
            placeholder={"0000-000-000-000-000"}
          />
          <div className={"searchSubmitButtonBox"} style={{ marginLeft: 6 }}>
            <input type="submit" className={"searchSubmitButton"} value="" />
            <div className={"submitPlusFont"}>+</div>
          </div>
        </form>
      </div>
      <div
        className={"profileTextSub"}
        style={{ alignSelf: "flex-start", marginTop: 20 }}
      >
        + New OTA List
      </div>

      <div className={"profileTextSub"} style={local.otaLNew}>
        <Dropdown
          options={updateOps}
          onChange={selectUpdateDropList}
          value={urlOTAList}
          placeholder="12345.bin"
        />
        <div style={{ marginTop: 4 }}>
          success ver.
          <input
            type="text"
            className={"portfolioShares"}
            style={{ minWidth: 60, marginLeft: 6 }}
            value={targetName}
            onChange={(e) => setTargetName(e.target.value)}
            placeholder={"2.14.2"}
          />
          panels only.
          <input
            type="text"
            className={"portfolioShares"}
            style={{ minWidth: 60, marginLeft: 6 }}
            value={targetName}
            onChange={(e) => setTargetName(e.target.value)}
            placeholder={"1x3"}
          />
        </div>
        <div
          style={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "row",
            marginTop: 4,
          }}
        >
          <input
            type="text"
            className={"portfolioShares"}
            style={{ minWidth: 240 }}
            value={deviceOTAList}
            onChange={onDeviceOTAList}
            placeholder={"0000-000-000-000-000"}
          />
          {validDeviceList?.id && targetName && (
            <div
              className={"profileTextSub"}
              onClick={() => newList()}
              style={local.submitShort}
            >
              <div>{`+`}</div>
            </div>
          )}
        </div>
      </div>

      <div
        className={"profileTextSub"}
        style={{ alignSelf: "flex-start", marginTop: 20 }}
      >
        Current OTA List
      </div>

      {multiList}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          className={"profileTextSub"}
          style={local.lookup}
          onClick={() => setTable(!table)}
        >{`devices:`}</div>
        <input
          type="text"
          className={"portfolioShares"}
          style={{ minWidth: 80, marginLeft: 16 }}
          value={panels}
          onChange={(e) => setPanels(e.target.value)}
          placeholder={"1x3"}
        />
        <input
          type="text"
          className={"portfolioShares"}
          style={{ minWidth: 80, marginLeft: 10, marginRight: 14 }}
          value={ver}
          onChange={(e) => setVer(e.target.value)}
          placeholder={"2.12.0"}
        />
        <div
          className={"profileTextSub"}
          style={filterSeen ? local.lookupActive : local.lookup}
          onClick={() => setFilterSeen(!filterSeen)}
        >{`timeStamp`}</div>
      </div>
      {table && (
        <div
          className={"flexCol"}
          style={{
            height: isMobile ? 200 : 400,
            width: isMobile ? 350 : 700,
            overflowX: "scroll",
            overflow: "scroll",
          }}
        >
          {showDevices}
        </div>
      )}
    </div>
  );
};
export default Ota;

let local = {
  idRow: {
    alignSelf: "stretch",
    height: 30,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 6,
    paddingLeft: 6,
  },
  typeRow: {
    width: 100,
    height: 30,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 3,
    paddingLeft: 3,
    cursor: "pointer",
  },
  typeRowActive: {
    width: 100,
    height: 30,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 3,
    paddingLeft: 3,
    color: "#FFF",
    backgroundColor: "#888",
    cursor: "pointer",
  },
  otaL: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    border: "1px solid #ccc",
    padding: 4,
    paddingTop: 6,
    paddingBottom: 6,
    maxHeight: 200,
    overflow: "scroll",
    marginBottom: 20,
  },
  otaLNew: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "column",
    border: "1px solid #ccc",
    padding: 4,
    paddingTop: 6,
    paddingBottom: 6,
    marginBottom: 100,
  },
  otaLHeader: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 3,
    alignItems: "center",
  },
  submitShort: {
    border: "1px solid #888",
    borderRadius: 2,
    width: 50,
    height: 36,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#888",
    color: "#fff",
    fontSize: 17,
  },
  lookup: {
    border: "1px solid #888",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    height: 36,
    marginBottom: 10,
    cursor: "pointer",
  },
  lookupActive: {
    border: "1px solid #888",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    height: 36,
    marginBottom: 10,
    backgroundColor: "#888",
    color: "#fff",
    cursor: "pointer",
  },
};
