import React, { Component } from "react";
import Switch from "react-input-switch";
import Axios from "axios";

import TimezoneSelect from "../settings/TimezoneSelect";
import Sleep from "../settings/Sleep";
import Clock from "../settings/Clock";
import Display from "../settings/Display";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      line1: ["stocks"],
      line2: [""],
      textString: "",
      canSleep: false,
      has1p: false,
    };
  }

  componentDidMount() {
    this.checkDeviceVer();
    this.checkIs1p();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceArr !== this.props.deviceArr) {
      this.checkIs1p();
    }
  }

  checkVer = (baseVersion, queryVersion) => {
    const baseParts = baseVersion.split(".").map(Number);
    const queryParts = queryVersion.split(".").map(Number);

    for (let i = 0; i < 3; i++) {
      if (queryParts[i] > baseParts[i]) {
        return true;
      } else if (queryParts[i] < baseParts[i]) {
        return false;
      }
    }
    return true; // Versions are equal
  };

  checkDeviceVer = async () => {
    this.props.deviceArr?.map(async (a) => {
      Axios.get(`https://quotron.co/deviceVer/${a.id}/${this.props.uid}`).then((res) => {
        if (res?.data?.versionArr) {
          let vArr = res?.data?.versionArr;
          let thisVer = vArr[vArr.length - 1];
          if (this.checkVer("2.14.5", thisVer)) {
            this.setState({ canSleep: true });
          } else {
            //  console.log("nop");
          }
        }
      });
    });
  };

  checkIs1p = async () => {
    this.props.deviceArr?.map(async (a) => {
      let sku = await Axios.get(`https://quotron.co/getSku/${a.id}`).then((res) => res.data);
      console.log("sku", a.id, sku);
      if (sku) {
        this.setState({ has1p: true });
      }
    });
  };

  setTimezone = (pick) => {
    //let db = getDatabase();
    //let { uid } = this.props;
    //set(ref(db, "users/" + uid + "/preferences/timezone"), pick.value);
    this.props.togglePrefSettings("timezone", pick.value);
  };

  onTextChange = (event) => {
    let textString = event.target.value;
    this.setState({ textString });
  };

  onTextSubmit = (event) => {
    event.preventDefault();
    let { textString } = this.state;
    let { uid } = this.props;

    var options = {
      method: "POST",
      url: "https://quotron.co/customString/",
      data: {
        uid,
        obj: { string: textString, times: 3 },
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options)
      .then((res) => {
        console.log("customString", res);
        this.setState({ textString: "" });
      })
      .catch((err) => {
        console.log("customString err", err);
      });
  };

  render() {
    let { deviceIDError, deviceID, activeDevice, textString } = this.state;
    let { isMobile, preferences, darkmode, handleDarkModeChange, isAdmin, activePref, uid, getUserData, deviceArr } = this.props;

    console.log("settings props.activePref", activePref);

    let line1Data = this.props?.preferences?.line1Data || [];
    let line2Data = this.props?.preferences?.line2Data || [];

    let speedArr = [1, 2, 3];
    let showSpeeds = speedArr.map((a, k) => {
      return (
        <div
          key={k}
          className={"deviceSpeedBox"}
          onClick={() => {
            this.setSpeed(a);
          }}
          style={{
            border: "1px solid #ccc",
            fontWeight: darkmode && preferences?.speed == a ? 400 : 300,
            borderColor: darkmode ? "#555" : "#ccc",
            backgroundColor: preferences?.speed == a ? (darkmode ? "#728cd4" : "#999") : darkmode ? "#272727" : "#fbf7f5",
            color: preferences?.speed == a ? (darkmode ? "#000" : "#fff") : darkmode ? "#ccc" : "#000",
          }}
        >
          {a}
        </div>
      );
    });

    let brightnessArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    let showBrights = brightnessArr.map((a, k) => {
      return (
        <div
          className={"deviceSpeedBox"}
          onClick={() => {
            this.setBrightness(a);
          }}
          style={{
            border: "1px solid #ccc",
            fontWeight: darkmode && preferences?.brightness == a ? 400 : 300,
            borderColor: darkmode ? "#555" : "#ccc",
            backgroundColor: preferences?.brightness == a ? (darkmode ? "#728cd4" : "#999") : darkmode ? "#272727" : "#fbf7f5",
            color: preferences?.brightness == a ? (darkmode ? "#000" : "#fff") : darkmode ? "#ccc" : "#000",
          }}
        >
          {a}
        </div>
      );
    });

    return (
      <div>
        {activePref == "custom" && (
          <div>
            <div
              className={"graphTextName"}
              style={{
                fontSize: 16,
                alignSelf: "flex-start",
                color: darkmode ? "#ccc" : "#444",
                marginBottom: 6,
                marginTop: 10,
                marginLeft: 2,
              }}
            >
              App Settings:
            </div>
            <div className={darkmode ? "prefBoxDark" : "prefBox"}>
              <div className={"prefRow"} style={{ marginTop: 6 }}>
                <div className={"settingsText"} style={{ marginRight: 8 }}>
                  Dark Mode:
                </div>
                <Switch
                  style={{ height: 18, width: 28 }}
                  styles={{
                    track: {
                      backgroundColor: darkmode ? "#555" : "#ccc",
                    },
                    trackChecked: {
                      backgroundColor: darkmode ? "#728cd4" : "#99ace0",
                    },
                  }}
                  onChange={handleDarkModeChange}
                  value={darkmode ? 1 : 0}
                />
              </div>
            </div>
          </div>
        )}
        {activePref == "clock" && (
          <div style={{ minWidth: 350 }}>
            <div
              className={"graphTextName"}
              style={{ fontSize: 16, alignSelf: "flex-start", color: darkmode ? "#ccc" : "#444", marginBottom: 6, marginTop: 6 }}
            >
              Timezone:
            </div>
            <div
              className={darkmode ? "prefBoxDark settingsText" : "prefBox settingsText"}
              style={{ alignSelf: "stretch", alignItems: "flex-start", flexDirection: "row", alignItems: "center", paddingTop: 6 }}
            >
              <div className={"settingsText"} style={{ marginRight: 8 }}>
                Timezone:
              </div>
              <TimezoneSelect darkmode={darkmode} setTimezone={this.setTimezone} preferences={preferences} />
            </div>
            <Clock
              uid={this.props?.uid}
              darkmode={darkmode}
              isMobile={isMobile}
              preferences={preferences}
              has1p={this.state.has1p}
              togglePrefSettings={this.props.togglePrefSettings}
            />
          </div>
        )}

        {(isAdmin || this.state.canSleep) && activePref == "clock" && (
          <Sleep uid={this.props?.uid} darkmode={darkmode} isMobile={isMobile} />
        )}

        {activePref == "devices" && (
          <Display
            darkmode={darkmode}
            isMobile={isMobile}
            getUserData={getUserData}
            uid={uid}
            deviceArr={deviceArr}
            preferences={preferences}
          />
        )}

        {activePref == "custom" && false && (
          <div>
            <div
              className={"graphTextName"}
              style={{
                fontSize: 16,
                alignSelf: "flex-start",
                color: darkmode ? "#ccc" : "#444",
                marginBottom: 6,
                marginTop: 6,
              }}
            >
              Custom:
            </div>
            <div className={darkmode ? "prefBoxDark" : "prefBox"}>
              <div
                style={{
                  alignSelf: "stretch",
                  marginBottom: 4,
                  width: 330,
                }}
              >
                <div className={darkmode ? "deviceidtextDark" : "deviceidtext"}>Send Message to Quotron:</div>
                <form
                  onSubmit={(e) => this.onTextSubmit(e)}
                  style={{
                    display: "flex",
                    alignSelf: "stretch",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    className={
                      isMobile
                        ? darkmode
                          ? "settingsDeviceAddMobileDark"
                          : "settingsDeviceAddMobile"
                        : darkmode
                        ? "settingsDeviceAddDark"
                        : "settingsDeviceAdd"
                    }
                    style={{
                      backgroundColor: deviceIDError ? "#ff7f7f" : darkmode ? "#272727" : "#FFF",
                      color: darkmode ? "#ccc" : "#000",
                    }}
                    style={{ minWidth: 288 }}
                    value={textString}
                    onChange={this.onTextChange}
                  />
                  <div onClick={(e) => this.onTextSubmit(e)} className={"searchSubmitButtonBox"} style={{ marginTop: -10 }}>
                    <input
                      type="submit"
                      className={darkmode ? "searchSubmitButtonDark" : "searchSubmitButton"}
                      value=""
                      style={{ height: 38, width: 38 }}
                    />
                    <div className={darkmode ? "submitPlusFontDark" : "submitPlusFont"} style={{ left: 9, top: 9 }}>
                      +
                    </div>
                  </div>
                </form>
                <div className={"profileTextSub"} style={{ width: 300 }}>{`*custom message will run 3 times`}</div>

                {false && (
                  <div className={"flexRow"} style={{ alignItems: "center" }}>
                    <div className={darkmode ? "deviceidtextDark" : "deviceidtext"} style={{ marginRight: 6 }}>
                      Always on :
                    </div>
                    <Switch
                      style={{ height: 18, width: 28 }}
                      styles={{
                        track: {
                          backgroundColor: darkmode ? "#555" : "#ccc",
                        },
                        trackChecked: {
                          backgroundColor: darkmode ? "#728cd4" : "#99ace0",
                        },
                      }}
                      onChange={() => {}}
                      value={true ? 1 : 0}
                    />
                  </div>
                )}
                {false && (
                  <div className={"flexRow"} style={{ alignItems: "center" }}>
                    <div className={darkmode ? "deviceidtextDark" : "deviceidtext"} style={{ marginRight: 6 }}>
                      {`Rotate within stream :`}
                    </div>
                    <Switch
                      style={{ height: 18, width: 28 }}
                      styles={{
                        track: {
                          backgroundColor: darkmode ? "#555" : "#ccc",
                        },
                        trackChecked: {
                          backgroundColor: darkmode ? "#728cd4" : "#99ace0",
                        },
                      }}
                      onChange={() => {}}
                      value={true ? 1 : 0}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Settings;
