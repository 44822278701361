import React from "react";
import plus from "../../imgs/plus.png";
import closeX from "../../imgs/closeX.png";

function ListNew(props) {
  let {
    footballData,
    footballInput,
    activeTeam,
    activeTeamFav,
    networkErr,
    preferences,
    allTeamsArr,
    favTeamsArrFootball,
    removeTeam,
    allGamesArr,
    darkmode,
  } = props;

  /*
  let showStockSort = "abc";
  let showSortCaret = "fa-caret-up";

  var options = {
    timeZone: "America/New_York",
    hour12: false,
    hour: "2-digit",
  };
  var d = new Date();
  let hour = d.toLocaleString("en-US", options);
  let day = d.getDay();
  let minute = d.getMinutes();
  hour = Number(hour);

  let teams = [
    { name: "bobbys", id: 123333 },
    { name: "kappas", id: 125333 },
    { name: "oflay", id: 124333 },
  ];

  console.log("favTeamsArrFootball", favTeamsArrFootball);

  console.log("allGamesArr", allGamesArr);

  let notInGames = allTeamsArr.map((a) => {
    let notIn = true;
    favTeamsArrFootball.map((b) => {
      if (a.slug == b.slug) {
        notIn = false;
      }
    });
    if (notIn) {
      return a;
    }
  });

  notInGames = notInGames.filter((a) => a);

  notInGames = notInGames.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  let myTeamSlugs = favTeamsArrFootball.map((a) => a.slug);
  console.log("myTeamSlugs", myTeamSlugs);
  let upcomingGames = allGamesArr.map((a) => {
    if (
      myTeamSlugs.includes(a?.homeTeam?.slug) ||
      myTeamSlugs.includes(a?.awayTeam?.slug)
    ) {
      return a;
    }
    return null;
  });

  upcomingGames = upcomingGames.filter((a) => a);

  let today = new Date();
  let oneWeek = new Date(today);
  oneWeek.setDate(oneWeek.getDate() + 7);
  let todayEpoch = today.valueOf() / 1000;
  let oneWeekEpoch = oneWeek.valueOf() / 1000;

  let oneWeekGames = upcomingGames.filter((a) => {
    console.log(
      "a.startTimestamp < oneWeekEpoch",
      a.startTimestamp < oneWeekEpoch,
      a.startTimestamp,
      oneWeekEpoch
    );
    return a.startTimestamp <= oneWeekEpoch && a.startTimestamp >= todayEpoch;
  });

  console.log("oneWeekGames", oneWeekGames);

  let showUpcomingGames = oneWeekGames.map((a, k) => {
    let ms = a?.startTimestamp * 1000;
    let gameDate = new Date(ms);
    let dformat =
      [
        gameDate.getMonth() + 1,
        gameDate.getDate(),
        gameDate.getFullYear(),
      ].join("/") +
      " " +
      [
        gameDate.getHours(),
        gameDate.getMinutes() < 10
          ? "0" + gameDate.getMinutes()
          : gameDate.getMinutes(),
      ].join(":");

    return (
      <div key={k} className={"favListItem"} style={{ marginLeft: 20 }}>
        <div className={"favListLeft"}>
          <div className={"favListSymbol"}>
            <div>{a?.homeTeam?.name}</div>
            <div>{a?.awayTeam?.name}</div>
          </div>
        </div>
        <div className={"favListRight"}>
          <div className={"favItemStockPrice"}>
            <div style={{ height: 20 }}>{}</div>
            <div style={{ opacity: 0.6 }}>{dformat}</div>
          </div>
        </div>
      </div>
    );
  });

  let showMyTeams = favTeamsArrFootball.map((a, k) => {
    let weatherButtonCss = "favListItem";
    if (activeTeamFav == k) {
      weatherButtonCss = "favListItemActive";
    }
    console.log("a.slug", a.slug);
    let gamesIncluding = allGamesArr.filter(
      (b) => b.awayTeam?.slug == a.slug || b.homeTeam?.slug == a.slug
    );
    console.log("gamesIncluding", gamesIncluding);
    gamesIncluding = gamesIncluding.sort(
      (b, c) => b.startTimestamp - c.startTimestamp
    );

    let showGamesIncluding = gamesIncluding.map((b, j) => {
      let ms = b.startTimestamp * 1000;
      let gameDate = new Date(ms);
      //gameDate.setUTCSeconds(ms);



      let dformat =
        [
          gameDate.getMonth() + 1,
          gameDate.getDate(),
          gameDate.getFullYear(),
        ].join("/") +
        " " +
        [
          gameDate.getHours(),
          gameDate.getMinutes() < 10
            ? "0" + gameDate.getMinutes()
            : gameDate.getMinutes(),
        ].join(":");

      let showScore =
        b.scoreHomeAwaySlug == "undefined:undefined" ? "" : b.scoreHomeAwaySlug;

      return (
        <div key={j} className={"favListItem"} style={{ marginLeft: 20 }}>
          <div className={"favListLeft"}>
            <div className={"favListSymbol"}>
              <div style={{ backgroundColor: b.winner == 1 ? "#ddd" : "none" }}>
                {b.homeTeam.name}
              </div>
              <div style={{ backgroundColor: b.winner == 2 ? "#ddd" : "none" }}>
                {b.awayTeam.name}
              </div>
            </div>
          </div>
          <div className={"favListRight"}>
            <div className={"favItemStockPrice"}>
              <div style={{ height: 20 }}>{showScore}</div>
              <div style={{ opacity: 0.6 }}>{dformat}</div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div key={k} style={{ display: "flex", flexDirection: "column" }}>
        <div className={"favListBox"}>
          <button
            className={weatherButtonCss}
            onClick={() => props.makeTeamActiveFav(k)}
          >
            <div className={"favListLeft"}>
              <div className={"favListSymbol"}>{a.name}</div>
              <div className={"favListName"}>{}</div>
            </div>
            <div className={"favListRight"}>
              <div className={"favItemStockPrice"}>{a.id}</div>
            </div>
          </button>
          {activeTeamFav == k && (
            <button onClick={() => props.removeTeam(a)}>
              <img src={closeX} className={"removeButtonTeam"} />
            </button>
          )}
        </div>
        {activeTeamFav == k && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 10,
            }}
          >
            {showGamesIncluding}
          </div>
        )}
      </div>
    );
  });

  let showTeams = notInGames.map((a, k) => {
    let weatherButtonCss = "favListItem";
    if (activeTeam == k) {
      weatherButtonCss = "favListItemActive";
    }

    return (
      <div key={k} className={"favListBox"}>
        <button
          className={weatherButtonCss}
          onClick={() => props.makeTeamActive(k)}
        >
          <div className={"favListLeft"}>
            <div className={"favListSymbol"}>{a.name}</div>
            <div className={"favListName"}>{}</div>
          </div>
          <div className={"favListRight"}>
            <div className={"favItemStockPrice"}>{a.id}</div>
          </div>
        </button>
        {activeTeam == k && (
          <button onClick={() => props.addTeam(a)}>
            <img src={plus} className={"addButtonTeam"} />
          </button>
        )}
      </div>
    );
  });
  */

  return (
    <div className={darkmode ? "prefBoxDark" : "prefBox"}>
      <div
        className={"graphTextName"}
        style={{ alignSelf: "flex-start", opacity: 0.8, minWidth: 320 }}
      ></div>
    </div>
  );
}

/*
<form
  style={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }}
  onSubmit={props.onFootballSubmit}
>
  <input
    type="text"
    className={"searchSubmitBoxFootball"}
    value={footballInput}
    onChange={props.onFootballInput}
    placeholder="Detroit Lions"
  />
  <div className={"searchSubmitButtonBox"}>
    <input type="submit" className={"searchSubmitButton"} value="" />
    <div className={"submitPlusFont"}>+</div>
  </div>
</form>
*/

export default ListNew;
