import React, { useState, useEffect } from "react";
import Axios from "axios";

import BrightnessSlider from "../settings/BrightnessSlider";
import SpeedSlider from "../settings/SpeedSlider";
import closeX from "../../imgs/closeX.png";
import closeX2 from "../../imgs/closeX2.png";

import closeXDark from "../../imgs/closeXDark.png";

const Display = ({ darkmode, isMobile, getUserData, uid, deviceArr, preferences }) => {
  const [deviceID, setDeviceID] = useState("");
  const [deviceIDError, setDeviceIDError] = useState(false);
  const [activeDevice, setActiveDevice] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [display, setDisplay] = useState(null);
  const [is1p, setIs1p] = useState([]);
  const [loaded, setLoaded] = useState(false);

  //console.log("display- prefs", preferences);

  useEffect(() => {
    getUser();
  }, [uid]);

  useEffect(() => {
    getSkus();
  }, [deviceArr]);

  let getSkus = async () => {
    deviceArr?.map(async (a) => {
      let sku = await Axios.get(`https://quotron.co/getSku/${a.id}`).then((res) => res.data);
      console.log("sku", a.id, sku);
      if (sku) {
        setIs1p((prev) => [...prev, a.id]);
      }
    });
  };

  let getUser = async () => {
    try {
      let res = await Axios.get(`https://quotron.co/user/${uid}`);

      if (res.data?.length > 0) {
        let user = res.data[0];
        console.log("display in", user.display || null);

        setDisplay(user.display || null);
        setLoaded(true);
      }
    } catch (err) {
      setLoaded(true);
    }
  };

  let onDeviceIdInput = (event) => {
    setDeviceID(event.target.value);
    setDeviceIDError(false);
  };

  let removeDevice = async (dId) => {
    let res = await Axios.get(`https://quotron.co/removeDeviceId/${dId}/${uid}`);
    console.log("removeDeviceId res", res.data);
    getUserData();
  };

  let registerDevice = async () => {
    setDeviceIDError(false);

    if (deviceID == "") {
      setDeviceIDError(true);
      return;
    }

    let formattedDevice = deviceID.replaceAll("-", "").replaceAll(" ", "");
    let format2 =
      formattedDevice.slice(0, 4) +
      "-" +
      formattedDevice.slice(4, 7) +
      "-" +
      formattedDevice.slice(7, 10) +
      "-" +
      formattedDevice.slice(10, 13) +
      "-" +
      formattedDevice.slice(13);

    Axios.get(`https://quotron.co/deviceCheck/${format2}/${uid}`).then(async (res) => {
      console.log("deviceCheck", res.data);
      if (res.data?.id && !res.data?.account) {
        await Axios.get(`https://quotron.co/setDeviceId/${format2}/${uid}`);
        setDeviceID("");
        setActiveDevice(null);
        getUserData();
      } else {
        setDeviceIDError(true);
      }
    });
  };

  let setSpeed = (speed, dId) => {
    console.log("setSpeed", speed, dId);
    Axios.post("https://quotron.co/display", { uid, type: "speed", value: speed, dId }).then((res) => {
      console.log("setSpeed res", res.data);
      getUser();
    });
  };

  let setBrightness = (brightness, dId) => {
    console.log("setBrightness", brightness, dId);
    Axios.post("https://quotron.co/display", { uid, type: "brightness", value: brightness, dId }).then((res) => {
      console.log("setBrightness res", res.data);
      getUser();
    });
  };

  let activeButtonBg = darkmode ? "#728cd4" : "#999";
  let buttonBg = darkmode ? "#272727" : "#fbf7f5";
  let activeButtonText = darkmode ? "#000" : "#fff";
  let buttonText = darkmode ? "#aaa" : "#000";

  let showDevices = deviceArr.map((a, k) => {
    let weatherButtonCss = darkmode ? "favListItemDark" : "favListItem";
    if (activeDevice == k) {
      weatherButtonCss = darkmode ? "favListItemDarkActive" : "favListItemActive";
    }

    let dDisplay = {
      brightness: display?.[a.id]?.brightness || preferences?.brightness,
      speed: display?.[a.id]?.speed || preferences?.speed,
      line1Data: display?.[a.id]?.line1Data || preferences?.line1Data,
      line2Data: display?.[a.id]?.line2Data || preferences?.line2Data,
    };
    if (!loaded) {
      dDisplay = {
        brightness: display?.[a.id]?.brightness || preferences?.brightness,
        speed: display?.[a.id]?.speed || preferences?.speed,
        line1Data: [],
        line2Data: [],
      };
    }

    console.log("loaded", loaded);

    //console.log("dDisplay", dDisplay);
    let yes1p = is1p.includes(a.id);

    let setLine1 = (type) => {
      let newLine1 = dDisplay.line1Data;
      if (newLine1?.includes(type)) {
        newLine1 = newLine1?.filter((a) => a != type);
      } else {
        if (!newLine1) {
          newLine1 = [];
        }
        newLine1.push(type);
      }
      console.log("newLine1", a.id, newLine1);
      dDisplay.line1Data = newLine1;
      setDisplay((prevDisplay) => ({
        ...prevDisplay,
        [a.id]: {
          ...prevDisplay[a.id],
          line1Data: newLine1,
        },
      }));
      Axios.post("https://quotron.co/display", { uid, type: "line1Data", value: newLine1, dId: a.id }).then((res) => {
        console.log("setDiplay line1Data res", res.data);

        getUser();
      });
    };

    let setLine2 = (type) => {
      let newLine2 = dDisplay.line2Data;
      if (newLine2?.includes(type)) {
        newLine2 = newLine2?.filter((a) => a != type);
      } else {
        if (!newLine2) {
          newLine2 = [];
        }
        newLine2.push(type);
      }
      console.log("newLine2", a.id, newLine2);
      dDisplay.line2Data = newLine2;
      setDisplay((prevDisplay) => ({
        ...prevDisplay,
        [a.id]: {
          ...prevDisplay[a.id],
          line2Data: newLine2,
        },
      }));
      console.log("set", dDisplay.line2Data);

      //this.props.togglePrefSettings("line2Data", line2Data);
      Axios.post("https://quotron.co/display", { uid, type: "line2Data", value: newLine2, dId: a.id }).then((res) => {
        console.log("setDiplay line2Data res", res.data);
        getUser();
      });
    };

    console.log("dDisplay?.brightness", dDisplay?.brightness);

    return (
      <div>
        <div key={k} className={"favListBox"}>
          <div
            className={weatherButtonCss}
            onClick={() => {
              if (activeDevice == k) {
                setActiveDevice(null);
              } else {
                setActiveDevice(k);
              }
              setConfirm(null);
            }}
            style={{
              borderRadius: 2,
              borderWidth: activeDevice == k ? 0 : 1,
              paddingLeft: 4,
              color: darkmode ? "#bbb" : activeDevice == k ? "#fff" : "#555",
              backgroundColor: darkmode ? "#333" : activeDevice == k ? "#777" : "#fbf7f5",
            }}
          >
            {a.id}
          </div>
          {confirm && confirm == k + 1 && (
            <div
              className={"profileTextSub"}
              style={{
                border: darkmode ? "1px solid #555" : "1px solid #888",
                borderRadius: 3,
                width: 258,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 2,
                height: 42,
                marginRight: 5.5,
                paddingLeft: 4,
                backgroundColor: darkmode ? "#222" : "#fbf7f5",
              }}
            >
              <div
                onClick={() => setConfirm(false)}
                style={{ marginLeft: 6, fontSize: 15, marginRight: 6, color: darkmode ? "#ccc" : "#444" }}
              >
                confirm delete
              </div>
              <div
                style={{
                  height: 40,
                  width: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: darkmode ? "#222" : "#fbf7f5",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ff4060",
                    height: 36,
                    width: 36,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: -5,
                  }}
                >
                  <img
                    src={darkmode ? closeX2 : closeXDark}
                    className={"removeButtonXSettingsDisplay"}
                    style={{ marginTop: 1, marginLeft: 6, marginRight: 6 }}
                  />
                </div>
              </div>
            </div>
          )}
          {activeDevice == k && !confirm && (
            <button onClick={() => setConfirm(k + 1)}>
              <img src={darkmode ? closeXDark : closeXDark} className={"removeButtonXSettings"} style={{ marginRight: 12 }} />
            </button>
          )}
        </div>
        {activeDevice == k && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: darkmode ? "#333" : "#777",
                marginTop: -48,
                padding: 3,
                paddingTop: 42,
                marginBottom: 10,
                borderRadius: 2,
              }}
            >
              <div style={{ backgroundColor: darkmode ? "#222" : "#fbf7f5", padding: 5, paddingTop: 16, borderRadius: 2 }}>
                <div
                  className={"settingsText"}
                  style={{
                    marginTop: 0,
                    marginBottom: 2,
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: 10, marginTop: -9, color: darkmode ? "#ddd" : "#000" }}>Scroll Speed:</div>
                  {loaded && <SpeedSlider darkmode={darkmode} setValue={(speed) => setSpeed(speed, a.id)} value={dDisplay?.speed} />}
                </div>
                <div
                  className={"settingsText"}
                  style={{
                    marginBottom: 0,
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    width: 300,
                  }}
                >
                  <div style={{ marginRight: 10, marginTop: 6, color: darkmode ? "#ddd" : "#000" }}>Brightness:</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      marginLeft: 8,
                    }}
                  >
                    {loaded && (
                      <BrightnessSlider
                        darkmode={darkmode}
                        setValue={(brightness) => setBrightness(brightness, a.id)}
                        value={dDisplay?.brightness}
                      />
                    )}
                  </div>
                </div>

                <div
                  className={"settingsText"}
                  style={{
                    marginBottom: 10,
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexWrap: "wrap",
                    border: darkmode ? "0px solid #555" : "0px solid #ccc",
                    borderBottomWidth: 1,
                    borderTopWidth: 1,
                    paddingTop: 10,
                    paddingBottom: 10,
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      marginRight: 10,
                      color: darkmode ? "#ddd" : "#000",
                    }}
                  >
                    Line #1:
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                      width: 260,
                    }}
                  >
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("stocks");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("stocks") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("stocks") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("stocks") && darkmode ? 400 : 300,
                      }}
                    >
                      Stocks
                    </div>
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("crypto");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("crypto") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("crypto") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("crypto") && darkmode ? 400 : 300,
                      }}
                    >
                      Crypto
                    </div>
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("forex");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("forex") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("forex") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("forex") && darkmode ? 400 : 300,
                      }}
                    >
                      Forex
                    </div>
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("markets");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("markets") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("markets") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("markets") && darkmode ? 400 : 300,
                      }}
                    >
                      Markets
                    </div>
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("comms");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("comms") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("comms") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("comms") && darkmode ? 400 : 300,
                      }}
                    >
                      Comm.
                    </div>
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("nfl");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("nfl") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("nfl") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("nfl") && darkmode ? 400 : 300,
                      }}
                    >
                      NFL
                    </div>
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("nba");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("nba") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("nba") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("nba") && darkmode ? 400 : 300,
                      }}
                    >
                      NBA
                    </div>
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("nhl");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("nhl") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("nhl") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("nhl") && darkmode ? 400 : 300,
                      }}
                    >
                      NHL
                    </div>
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("mlb");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("mlb") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("mlb") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("mlb") && darkmode ? 400 : 300,
                      }}
                    >
                      MLB
                    </div>
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("ncaa");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("ncaa") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("ncaa") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("ncaa") && darkmode ? 400 : 300,
                      }}
                    >
                      NCAA
                    </div>
                    <div
                      className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                      onClick={() => {
                        setLine1("weather");
                      }}
                      style={{
                        backgroundColor: dDisplay?.line1Data?.includes("weather") ? activeButtonBg : buttonBg,
                        color: dDisplay?.line1Data?.includes("weather") ? activeButtonText : buttonText,
                        fontWeight: dDisplay?.line1Data?.includes("weather") && darkmode ? 400 : 300,
                      }}
                    >
                      Weather
                    </div>
                  </div>
                </div>
                {!yes1p && (
                  <div
                    className={"settingsText"}
                    style={{
                      marginBottom: 10,
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                      paddingBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        marginRight: 10,
                        color: darkmode ? "#ddd" : "#000",
                      }}
                    >
                      Line #2:
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        width: 260,
                      }}
                    >
                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("stocks");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("stocks") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("stocks") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("stocks") && darkmode ? 400 : 300,
                        }}
                      >
                        Stocks
                      </div>
                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("crypto");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("crypto") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("crypto") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("crypto") && darkmode ? 400 : 300,
                        }}
                      >
                        Crypto
                      </div>
                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("forex");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("forex") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("forex") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("forex") && darkmode ? 400 : 300,
                        }}
                      >
                        Forex
                      </div>
                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("markets");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("markets") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("markets") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("markets") && darkmode ? 400 : 300,
                        }}
                      >
                        Markets
                      </div>
                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("comms");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("comms") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("comms") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("comms") && darkmode ? 400 : 300,
                        }}
                      >
                        Comm.
                      </div>

                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("nfl");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("nfl") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("nfl") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("nfl") && darkmode ? 400 : 300,
                        }}
                      >
                        NFL
                      </div>
                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("nba");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("nba") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("nba") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("nba") && darkmode ? 400 : 300,
                        }}
                      >
                        NBA
                      </div>
                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("nhl");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("nhl") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("nhl") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("nhl") && darkmode ? 400 : 300,
                        }}
                      >
                        NHL
                      </div>
                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("mlb");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("mlb") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("mlb") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("mlb") && darkmode ? 400 : 300,
                        }}
                      >
                        MLB
                      </div>

                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("ncaa");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("ncaa") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("ncaa") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("ncaa") && darkmode ? 400 : 300,
                        }}
                      >
                        NCAA
                      </div>
                      <div
                        className={darkmode ? "deviceLineBoxDark" : "deviceLineBox"}
                        onClick={() => {
                          setLine2("weather");
                        }}
                        style={{
                          backgroundColor: dDisplay?.line2Data?.includes("weather") ? activeButtonBg : buttonBg,
                          color: dDisplay?.line2Data?.includes("weather") ? activeButtonText : buttonText,
                          fontWeight: dDisplay?.line2Data?.includes("weather") && darkmode ? 400 : 300,
                        }}
                      >
                        Weather
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <div>
      <div
        className={"graphTextName"}
        style={{
          fontSize: 16,
          alignSelf: "flex-start",
          color: darkmode ? "#ccc" : "#444",
          marginBottom: 6,
          marginTop: 6,
        }}
      >
        Devices:
      </div>
      <div style={{ alignSelf: "stretch", marginBottom: 0 }}>
        <div className={"favList"} style={{ marginTop: 2 }}>
          {showDevices}
        </div>
      </div>
      <div style={{ alignSelf: "stretch", marginBottom: 4 }}>
        <div className={darkmode ? "deviceidtextDark" : "deviceidtext"}>Add Device:</div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="text"
            className={
              isMobile
                ? darkmode
                  ? "settingsDeviceAddMobileDark"
                  : "settingsDeviceAddMobile"
                : darkmode
                ? "settingsDeviceAddDark"
                : "settingsDeviceAdd"
            }
            style={{
              backgroundColor: deviceIDError ? "#ff7f7f" : darkmode ? "#272727" : "#FFF",
              color: deviceIDError ? "#000" : darkmode ? "#ccc" : "#000",
            }}
            value={deviceID}
            onChange={onDeviceIdInput}
            placeholder="123-456-789"
          />

          <div onClick={() => registerDevice()} className={"searchSubmitButtonBox"}>
            <input
              type="submit"
              className={darkmode ? "searchSubmitButtonDark" : "searchSubmitButton"}
              value=""
              style={{ height: 38, width: 38 }}
            />
            <div className={darkmode ? "submitPlusFontDark" : "submitPlusFont"} style={{ left: 9, top: 9 }}>
              +
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Display;
