import React, { useState } from "react";
import ReactSlider from "react-slider";

let UpcomingSlider = ({ darkmode, setValue, value, fast, setFast }) => {
  let [text, setText] = useState("days out:");

  let changeText = (res) => {
    if (res == 0) {
      setText("live only:");
    } else {
      setText("days out:");
    }
  };

  return (
    <div
      style={{
        marginBottom: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignSelf: "stretch",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <ReactSlider
          className="horizontal-slider-sports"
          marks
          markClassName="example-mark"
          defaultValue={value}
          min={0}
          max={14}
          thumbClassName={darkmode ? "example-thumb" : "example-thumb-light"}
          trackClassName="example-track"
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          onChange={(res) => setFast(res)}
          onAfterChange={(result) => {
            setValue(result);
            changeText(result);
          }}
        />
      </div>
      {fast == "0" ? (
        <div
          style={{
            alignSelf: "stretch",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginBottom: 12,
          }}
        >
          <div
            className={"profileTextSub"}
            style={{ fontSize: 15, marginBottom: 2 }}
          >{`live only`}</div>
          <div
            className={"profileTextSub"}
            style={{ fontSize: 15 }}
          >{`*will not stream upcoming schedule`}</div>
        </div>
      ) : (
        <div
          style={{
            alignSelf: "stretch",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginBottom: 12,
          }}
        >
          <div
            className={"profileTextSub"}
            style={{ fontSize: 15 }}
          >{`when not live,`}</div>
          <div
            className={"profileTextSub"}
            style={{ fontSize: 15 }}
          >{`will stream upcoming schedule`}</div>
          <div
            className={"profileTextSub"}
            style={{ fontSize: 15 }}
          >{`${fast} days out`}</div>
        </div>
      )}
      <div className={"medDivider"} style={{ marginTop: 10 }} />
    </div>
  );
};

export default UpcomingSlider;
