import React, { useState, useRef, useEffect } from "react";
import Axios from "axios";
import { formatDate, getOrdinalSuffix } from "../../util/helpers";

import plus from "../../imgs/plus.png";
import closeX from "../../imgs/closeX.png";
import closeXDark from "../../imgs/closeXDark.png";
import closeXWhite from "../../imgs/closeXWhite.png";

import RssButton from "./RssButton";

let News = ({ darkmode }) => {
  let [active, setActive] = useState(["general", "finance", "crypto", "forex"]);
  let [pages, setPages] = useState([]);
  useEffect(() => {
    getNews();
  }, []);

  let longDate = () => {
    const date = new Date();
    const formattedDate = formatDate(date);
    return (
      <div
        style={{
          color: darkmode ? "#ccc" : "#333",
          fontSize: 16,
          marginLeft: -3,
        }}
      >
        {formattedDate}
      </div>
    );
  };

  let shortDate = (dateString) => {
    const date = new Date(dateString);

    // Format the date parts
    const day = date.getDate(); // Day of the month
    const month = date.getMonth() + 1; // Month (0-indexed, so add 1)
    const year = date.getFullYear().toString().substr(-2); // Last two digits of the year

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Minutes, ensuring two digits
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    hours = hours.toString().padStart(2, "0"); // Ensuring two digits

    // Construct the formatted date string
    //const formattedDate = `${hours}:${minutes}${ampm} - ${day}/${month}/${year}`;
    const formattedDate = `${hours}:${minutes}${ampm} - ${month}/${day}/${year}`;

    return <div>{formattedDate}</div>;
  };

  let getNews = async () => {
    await Axios.get(`https://quotron.co/news/123`)
      .then((res) => {
        let order = res.data?.sort(
          (a, b) => new Date(b.publishedDate) - new Date(a.publishedDate)
        );
        console.log("order", order);
        setPages(order);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  let clickNews = (url) => {
    console.log("clickNews");
    window.open(url, "_blank", "noopener,noreferrer");
  };

  let Article = ({ stock, title, text, date, site, url }) => {
    return (
      <div
        key={url}
        onClick={() => clickNews(url)}
        className={darkmode ? "prefBoxDark" : "prefBox"}
        style={{ width: 350 }}
      >
        <div
          className={"graphTextName flexRow"}
          style={{
            alignSelf: "stretch",
            marginBottom: 8,
            justifyContent: "space-between",
          }}
        >
          <div className={"flexRow"}>
            <div>{stock}</div>
            <div
              className={"profileTextSub"}
              style={{ opacity: 0.7, marginLeft: 2 }}
            >{`- ${site}`}</div>
            <div
              className={"profileTextSub"}
              style={{ opacity: 0.5, marginLeft: 3 }}
            >{`⧉`}</div>
          </div>
          <div className={"profileTextSub"} style={{ minWidth: 118 }}>
            {shortDate(date)}
          </div>
        </div>
        <div className={"profileTextSub"} style={inStyle.divider} />
        <div className={"flexRow"} style={inStyle.titleRow}>
          <div>{title}</div>
          <div></div>
        </div>

        <div
          onClick={() => clickNews()}
          className={"profileTextSub"}
          style={{ maxHeight: false ? 2000 : 83, overflow: "hidden" }}
        >
          {text}
        </div>
      </div>
    );
  };

  let cap = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  let toggle = (itemName) => {
    if (active.includes(itemName)) {
      setActive(active.filter((item) => item !== itemName));
    } else {
      setActive([...active, itemName]);
    }
  };

  let Menu = () => {
    let activeMenu = darkmode ? "prefMenuItemBlue" : "prefMenuItemDark";
    let inainactiveMenu = darkmode ? "prefMenuItemLight" : "prefMenuItem";
    return (
      <div className={"prefMenu"} style={{ marginBottom: 6, marginLeft: -1 }}>
        <div
          className={active.includes("general") ? activeMenu : inainactiveMenu}
          onClick={() => toggle("general")}
        >
          general
        </div>
        <div
          className={active.includes("finance") ? activeMenu : inainactiveMenu}
          onClick={() => toggle("finance")}
        >
          finance
        </div>
        <div
          className={active.includes("forex") ? activeMenu : inainactiveMenu}
          onClick={() => toggle("forex")}
        >
          forex
        </div>
        <div
          className={active.includes("crypto") ? activeMenu : inainactiveMenu}
          onClick={() => toggle("crypto")}
        >
          crypto
        </div>
      </div>
    );
  };

  let showGeneral = pages?.map((a, k) => {
    if (!active?.includes(a.type)) return null;
    return (
      <Article
        stock={cap(a.type)}
        title={a.title}
        text={a.text}
        date={a.date}
        url={a.url}
        site={a.site}
      />
    );
  });

  return (
    <div style={{ alignSelf: "stretch", marginTop: 10 }}>
      <div
        className={"profileTextSub"}
        style={{
          marginBottom: 4,
          paddingLeft: 5,
          marginTop: 8,
          width: 350,
          marginBottom: 6,
        }}
      >
        {longDate()}
      </div>
      <Menu />
      {showGeneral}
    </div>
  );
};

let inStyle = {
  divider: {
    color: "#999",
    width: 50,
    marginLeft: 2,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    alignSelf: "flex-start",
    marginBottom: 8,
  },
  titleRow: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    marginBottom: 4,
  },
  progressBar: {
    alignSelf: "stretch",
    width: "100%",
    height: 4,
    background: "lightgrey",
    cursor: "pointer",
    marginBottom: 6,
  },
};

export default News;
