import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import plus from "../../imgs/plus.png";
import closeX from "../../imgs/closeX.png";
import { commList } from "../../util/helpers";
import closeXWhite from "../../imgs/closeXWhite.png";
import RssButton from "./RssButton";

let ListComms = (props) => {
  let { markets, darkmode, isMobile, preferences, hiddenComms } = props;

  let commArr = commList();
  let marketData = markets || [];

  let orderMap = {};
  commArr.forEach((item, index) => {
    orderMap[item.symbol] = index;
  });
  marketData.sort((a, b) => {
    return orderMap[a.symbol] - orderMap[b.symbol];
  });

  marketData = marketData.map((a) => {
    return {
      ...a,
      section: commArr.filter((b) => b.symbol == a.symbol)[0]?.section,
    };
  });

  let showFavs = (section = 1) => {
    return marketData.map((a, k) => {
      if (a.section != section) return null;
      let weatherButtonCss = darkmode ? "favListItemDark" : "favListItem";

      let isHidden = false;
      if (!hiddenComms.includes(a.symbol?.replace(".", "-"))) {
        isHidden = true;
      }

      let showPrice = a.price?.toFixed(2);
      let showChange = Number(a.change)?.toFixed(2);
      let upDown = preferences.upDown || "percentage";
      if (upDown == "percentage") {
        showChange = Number(a.changesPercentage)?.toFixed(2) + "%";
      }

      let changeColor = darkmode ? "#5fff5f" : "#0c870c";
      let showCaret = "fa fa-caret-up";

      if (a.change < 0) {
        changeColor = darkmode ? "#ef0101" : "#bd0101";
        showCaret = "fa fa-caret-down";
      }

      let shortcutSymbol = a.symbol;
      let showName = commArr?.filter((b) => b.symbol == a.symbol)[0]?.name;

      return (
        <div onClick={() => isMobile && props.inactiveComm(shortcutSymbol, isHidden)} key={k} className={"favListBox"}>
          <button className={weatherButtonCss} style={{ width: 330 }}>
            <div className={"favListLeft"}>
              <div style={{ minWidth: 36 }}>
                <RssButton
                  onClick={() => !isMobile && props.inactiveComm(shortcutSymbol, isHidden)}
                  activeRow={true}
                  inactive={isHidden}
                  darkmode={darkmode}
                />
              </div>
              <div className={"favListSymbol"}>{showName}</div>
              <div className={"favListName"}>{shortcutSymbol}</div>
            </div>
            <div className={"favListRight"}>
              <div className={`${showCaret} favItemCaret`} style={{ color: changeColor }}></div>
              <div className={"favItemChange"} style={{ color: changeColor }}>
                {`${showChange}`}
              </div>
              <div className={"favItemPrice"}>{showPrice}</div>
            </div>
          </button>
        </div>
      );
    });
  };

  return (
    <div className={darkmode ? "prefBoxDark" : "prefBox"}>
      <div className={"favList"} style={{ marginTop: 0 }}>
        {showFavs(4)}
      </div>
      <div style={{ opacity: 0.5 }}>--</div>
      <div className={"favList"} style={{ marginTop: 10 }}>
        {showFavs(1)}
      </div>
      <div style={{ opacity: 0.5 }}>--</div>
      <div className={"favList"} style={{ marginTop: 10 }}>
        {showFavs(2)}
      </div>
      <div style={{ opacity: 0.5 }}>--</div>
      <div className={"favList"} style={{ marginTop: 10 }}>
        {showFavs(3)}
      </div>
    </div>
  );
};

export default ListComms;
