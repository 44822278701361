//react function component
import React from "react";
import bfriday1 from "../imgs/bfriday3.jpg";
import cyber from "../imgs/cyber.png";

import closeX from "../imgs/closeX.png";
import closeXDark from "../imgs/closeXDark.png";

export default function Banner({ darkmode, isMobile, closeBanner }) {
  console.log("banner - darkmode", darkmode);

  let bannerClick = (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up to the parent
    window.open("https://quotron-7591.myshopify.com/collections/all", "_blank");
    closeBanner();
  };

  return (
    <div
      className={isMobile ? "glassDark" : "glass"}
      style={{
        marginTop: 0,
        height: "100vh",
        alignSelf: "stretch",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 200,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: isMobile ? "center" : "center",
      }}
      onClick={() => closeBanner()}
    >
      <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
        <div
          style={{
            border: darkmode ? "1.5px solid #FFF" : "1.5px solid #000",
            borderWidth: isMobile ? 1 : 1.5,
            padding: 20,
            position: "relative",
            margin: isMobile ? 10 : 0,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: isMobile ? -60 : -70,
              right: isMobile ? -10 : 0,
              height: 60,
              width: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={darkmode ? closeXDark : closeX}
              alt="close"
              style={{ height: isMobile ? 30 : 40, width: isMobile ? 30 : 40, opacity: 0.8 }}
            />
          </div>
          <img src={cyber} alt="banner" style={{ maxWidth: 1200, width: "100%", objectFit: "contain" }} onClick={bannerClick} />
        </div>
      </div>
    </div>
  );
}
