import React, { useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

let Activate = ({ darkmode }) => {
  let history = useHistory();

  let goToLogin = useCallback(() => {
    history.push("/setup?step=login"), [history];
  });

  let goToRegister = useCallback(() => {
    history.push("/setup?step=register"), [history];
  });

  let textColor = darkmode ? "#ccc" : " #000";

  return (
    <div
      className={darkmode ? "pageDark" : "page"}
      style={{ marginTop: 100, width: 300, color: textColor }}
    >
      <div
        className={"profileTextSub"}
        style={{
          fontSize: 20,
          border: "0px solid #777",
          borderBottomWidth: 1,
          padding: 3,
        }}
      >
        Activate Device
      </div>
      <div className={"flexCol"} style={{ alignSelf: "stretch" }}>
        <div
          className={"profileTextSub"}
          style={{ fontSize: 18, marginTop: 30 }}
        >
          • I already have an account
        </div>
        <div
          onClick={() => goToLogin()}
          className={"flexCenter graphTextName"}
          style={{
            height: 40,
            width: 300,
            border: "1px solid #777",
            borderRadius: 4,
            marginTop: 6,
          }}
        >
          login
        </div>

        <div
          className={"profileTextSub"}
          style={{ fontSize: 18, marginTop: 34 }}
        >
          • I do not have an account yet
        </div>
        <div
          onClick={() => goToRegister()}
          className={"flexCenter graphTextName"}
          style={{
            height: 40,
            width: 300,
            border: "1px solid #777",
            borderRadius: 4,
            marginTop: 6,
          }}
        >
          register
        </div>
      </div>
    </div>
  );
};

export default Activate;
