import React, { useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";

function SideBar(props) {
  let { isMobile, uid, dir, setSplashPage } = props;
  let barHeight = isMobile ? props.screenHeight - 74 : props.screenHeight;
  let widthCalc = isMobile ? "calc(100vw - 250px)" : "calc(100vw - 400px)";

  let history = useHistory();
  /*
  let handleRegister = useCallback(() => {
    props.toggleSettingsMobile();
    history.push("/setup?step=register"), [history];
  });
  */

  let handleLogin = useCallback(() => {
    props.toggleSettingsMobile();
    history.push("/setup?step=login"), [history];
  });

  let handleHome = () => {
    props.toggleSettingsMobile();
    setSplashPage("splash");
    history.push("/"), [history];
  };
  let handleFaq = () => {
    props.toggleSettingsMobile();
    setSplashPage("faq");
    history.push("/"), [history];
  };
  let handleSupport = () => {
    props.toggleSettingsMobile();
    setSplashPage("support");
    history.push("/"), [history];
  };
  /*
  let handleFeatures = () => {
    props.toggleSettingsMobile();
    setSplashPage("features");
    history.push("/"), [history];
  };
  let handlePricing = () => {
    props.toggleSettingsMobile();
    setSplashPage("pricing");
    history.push("/"), [history];
  };
  */

  let handleActivate = useCallback(() => {
    props.toggleSettingsMobile();
    history.push("/activate"), [history];
  });

  /*
  let isHome = dir == "/" || dir == "";
  if (uid) {
    isHome = false;
  }
  */

  let menu = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#333",
        width: isMobile ? 250 : 400,
        paddingTop: isMobile ? 0 : 20,
        height: barHeight,
        border: "0px solid #777",
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderRightWidth: 0,
      }}
    >
      <button
        className={"robotSide"}
        style={{ marginTop: 22, color: "#fff", width: 130 }}
        onClick={() => handleHome()}
      >
        Home
      </button>
      <div
        style={{
          height: 0.5,
          backgroundColor: "#fbf7f5",
          width: 36,
          opacity: 0.2,
          marginTop: 10,
        }}
      />

      <button
        className={"robotSide"}
        style={{ marginTop: 12, color: "#fff", width: 130 }}
        onClick={() => handleFaq()}
      >
        FAQ
      </button>
      <div
        style={{
          height: 0.5,
          backgroundColor: "#fbf7f5",
          width: 36,
          opacity: 0.2,
          marginTop: 10,
        }}
      />

      <button
        onClick={() => handleActivate()}
        className={"robotSide"}
        style={{ marginTop: 12, color: "#fff", width: 160 }}
      >
        Device Setup
      </button>
      <div
        style={{
          height: 0.5,
          backgroundColor: "#fbf7f5",
          width: 36,
          opacity: 0.2,
          marginTop: 10,
        }}
      />
      <button
        className={"robotSide"}
        style={{ marginTop: 12, color: "#fff", width: 130 }}
        onClick={() => handleSupport()}
      >
        Support
      </button>
      <div
        style={{
          height: 0.5,
          backgroundColor: "#fbf7f5",
          width: 36,
          opacity: 0.2,
          marginTop: 10,
        }}
      />

      <button
        onClick={() => handleLogin()}
        className={"robotSide"}
        style={{ marginTop: 16, color: "#fff" }}
      >
        login
      </button>
    </div>
  );
  if (props.uid) {
    menu = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#333",
          width: isMobile ? 250 : 400,
          height: barHeight,
        }}
      >
        <button
          className={"sideBarText"}
          onClick={props.openSettings}
          style={{ marginTop: isMobile ? 30 : 40 }}
        >
          settings
        </button>

        {props.isAdmin && (
          <button className={"sideBarText"} onClick={props.openAdmin}>
            admin
          </button>
        )}
        <div
          style={{
            height: 0.5,
            backgroundColor: "#fbf7f5",
            width: 36,
            opacity: 0.2,
            marginTop: 14,
          }}
        />
        <button
          className={"sideBarText"}
          style={{
            marginTop: 8,
            opacity: props.darkmode ? 1 : 0.6,
            fontWeight: 100,
          }}
          onClick={props.logout}
        >
          logout
        </button>
      </div>
    );
  }
  return (
    <div
      className={"sideBarMobile"}
      style={{
        position: "absolute",
        left: 0,
        display: "flex",
        flex: 1,
        flexDirection: "row",
        width: "100vw",
        transform: props.showSettingsMobile
          ? "translateX(0)"
          : `translateX(100vw)`,
        transition: "transform 250ms ease-in-out",
        zIndex: 50,
        height: barHeight,
        zIndex: 150,
        backgroundColor: "none",
      }}
    >
      <div
        onClick={props.toggleSettingsMobile}
        style={{
          width: widthCalc,
          height: barHeight,
          backgroundColor: "none",
        }}
      ></div>
      <div>
        {isMobile && (
          <div
            onClick={props.toggleSettingsMobile}
            style={{ display: "flex", flex: 1, height: 74 }}
          />
        )}
        {menu}
      </div>
    </div>
  );
}
export default SideBar;
