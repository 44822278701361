import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

let BoxChart = ({ activeDeviceRow, uid, isMobile }) => {
  let [boxHits, setBoxHits] = useState([]);

  useEffect(() => {
    getBoxHits(activeDeviceRow);
  }, [activeDeviceRow]);

  let getBoxHits = (boxId) => {
    if (!boxId) {
      return;
    }
    var options = {
      method: "GET",
      url: "https://quotron.co/admin/boxHits/" + uid + "/" + boxId,
      params: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      if (!res.data) return;
      setBoxHits(res.data);
    });
  };

  if (boxHits?.length < 1) return null;

  let chartData = [];

  boxHits.map((a, k) => {
    if (k == 0) return;
    let delay = boxHits[k].timestamp - boxHits[k - 1].timestamp;
    delay = delay / 1000;
    if (delay > 10 * 60) {
      delay = -1;
    }
    chartData.push({ timestamp: boxHits[k].timestamp, delay });
  });

  let CustomShow = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(payload[0]?.payload?.timestamp);
      const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because months are 0-indexed in JavaScript
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      let showDate = `${month}/${day} - ${hours}:${minutes}:${seconds}`;
      return (
        <div
          className="profileTextSub"
          style={{
            backgroundColor: "#fff",
            border: "1px solid #888",
            padding: 2,
            fontWeight: 300,
          }}
        >
          <div>{`Delay: ${payload[0].value}`}</div>
          <div>{`Date: ${showDate}`}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className={"settingsTitle"} style={{ marginTop: 10 }}>
        box Hits:
      </div>
      <div
        style={{
          border: "1px solid #777",
          paddingLeft: 6,
        }}
      >
        <LineChart
          width={isMobile ? 320 : 700}
          height={isMobile ? 140 : 300}
          data={chartData}
        >
          <Line
            type="monotone"
            dataKey={"delay"}
            stroke="#aaa"
            strokeWidth={1}
            dot={false}
            animationDuration={300}
          />
          <Tooltip content={<CustomShow />} />
          <XAxis dataKey="dateString" />
          <YAxis width={24} />
        </LineChart>
      </div>
    </>
  );
};
export default BoxChart;
