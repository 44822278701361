import React, { Component } from "react";

import { getAuth, confirmPasswordReset } from "firebase/auth";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPassword: "",
      resetPasswordError: false,
    };
  }

  onResetPasswordChange = (event) => {
    let resetPassword = event.target.value;
    this.setState({ resetPassword, resetPasswordError: false });
  };

  sendReset = () => {
    event.preventDefault();
    let { resetPassword } = this.state;
    let { oobCode } = this.props;
    const auth = getAuth();
    confirmPasswordReset(auth, oobCode, resetPassword)
      .then((res) => {
        console.log("confirmPasswordReset res", res);
        this.props.finishedResetPassword(resetPassword);
      })
      .catch((err) => {
        console.log("confirmPasswordReset err", err);
        if (err?.code?.includes("auth/weak-password")) {
          this.setState({ resetPasswordError: "weak password" });
        }
      });
    console.log("send reset");
  };

  render() {
    let { resetPassword, resetPasswordError } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: this.props.isMobile ? 78 : 20,
        }}
      >
        <div className={"resetPasswordText"}>new password</div>
        <form className={"registerBox"} onSubmit={this.sendReset}>
          <input
            type="text"
            className={"authInputBox"}
            style={{
              backgroundColor: resetPasswordError ? "#ff7f7f" : "#FFF",
            }}
            value={resetPassword}
            type="password"
            onChange={this.onResetPasswordChange}
            placeholder="*******"
          />
          <input
            type="submit"
            style={{ padding: 4, width: 100 }}
            value="Reset"
          />
          {resetPasswordError && (
            <div className={"authError"} style={{ marginTop: 14 }}>
              {resetPasswordError}
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default ResetPassword;
