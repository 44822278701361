import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Axios from "axios";
import { getDatabase, ref, onValue, child, get, set, update } from "firebase/database";
import {
  getAuth,
  getUser,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  applyActionCode,
  reload,
} from "firebase/auth";
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { withCookies } from "react-cookie";
import ReactJson from "react-json-view";
import loadingDots from "./imgs/loadingDots.gif";
import q2Blk from "./imgs/q2Blk.png";
import Auth from "./home/Auth";
import PrefMenu from "./home/PrefMenu";
import Header from "./home/Header";
import SideBar from "./home/SideBar";
import ResetPassword from "./home/ResetPassword";
import ListCrypto from "./home/lists/ListCrypto";
import ListMarkets from "./home/lists/ListMarkets";
import ListComms from "./home/lists/ListComms";
import ListStocks from "./home/lists/ListStocks";
import ListForex from "./home/lists/ListForex";
import Settings from "./home/lists/Settings";
import ListSport from "./home/lists/ListSport";
import ListNew from "./home/lists/ListNew";
import Weather from "./home/lists/Weather";
import News from "./home/lists/News";
import Store from "./home/lists/Store";
import Banner from "./home/Banner";

import ListPortfolio from "./home/lists/ListPortfolio";
import Admin from "./home/Admin";
import Profile from "./home/Profile";
//import Unknown from "./Unknown";
import Activate from "./home/Activate";
import Blank from "./home/Blank";

import { forexArr, commList } from "./util/helpers";

//stanDashboard firebase
const firebaseConfig = {
  apiKey: "AIzaSyC9f9HBhku_Gn0Hd-YZxp3WogXjrMVCdcg",
  authDomain: "auth.quotron.co",
  projectId: "standashboard-99094",
  storageBucket: "standashboard-99094.appspot.com",
  messagingSenderId: "871432953677",
  appId: "1:871432953677:web:f30caf1a1a21e59b6c6104",
  measurementId: "G-NLVNM53S35",
};

class Home extends Component {
  constructor(props) {
    super(props);

    let { cookies, match } = props;
    let lightmode = cookies.get("lightmode") == "true" || false;
    let banner1 = cookies.get("banner1") || 0;
    let banner = false;
    if (banner1 >= 2) {
      banner = false;
    }

    console.log("banner1 - constructor", banner1);

    let dir = match?.params?.dir || "";

    console.log("Home constructor");

    this.state = {
      dir,
      uid: null,
      user: {},
      loaded: false,
      mode: props.mode || "",
      splashPage: "splash",
      deviceOutput: "",
      resetPassword: false,
      resentVerifyEmail: false,
      showResetComplete: false,
      showSettingsMobile: false,
      activePref: "stocks",
      activePrefUpper: "finance",
      markets: [],
      activeMarket: 0,
      stockInput: "",
      stockData: [],
      stockEodData: [],
      activeStock: -1,
      forexData: [],
      activeForex: -1,
      forexInput: "",
      cryptoData: [],
      cryptoInput: "",
      portfolioStocks: [],
      activePortfolio: 0,
      portfolioInput: "",
      portfolioCrypto: false,
      portfolioInputCrypto: "",
      activeCrypto: -1,
      deviceArr: [],
      pageWidth: "100vw",
      preferences: {
        upDown: "value",
        speed: 2,
        brightness: 5,
        lightmode,
        timezone: "EST",
        menu: {
          finance: [
            { name: "stocks", on: true },
            { name: "crypto", on: true },
            { name: "markets", on: true },
            { name: "forex", on: true },
            { name: "comms", on: true },
          ],
          sports: [
            { name: "mlb", on: true },
            { name: "nfl", on: true },
            { name: "nhl", on: true },
            { name: "nba", on: true },
            { name: "ncaa", on: true },
          ],
          weather: [
            {
              name: "all",
              on: true,
            },
          ],
        },
      },
      cryptoSort: "mktD",
      stockList: [],
      showFiltered: [],
      hiddenMarkets: [],
      hiddenComms: [],
      verifyEmailError: null,
      portfolioData: [],
      isAdmin: false,
      username: null,
      justAdded: false,
      mongoStocks: [],
      mongoCrypto: [],
      locations: [],
      banner: banner,
    };
    this.pageRef = React.createRef();
    let apps = getApps().length;
    if (!apps) {
      const app = initializeApp(firebaseConfig);
    } else {
      //const app = getApp();
    }
  }

  componentDidMount() {
    this.getAuth();
    this.setState({ pageWidth: this.pageRef.current.offsetWidth });
    const analytics = getAnalytics();
    this.getMongoMarkets();
    this.getMongoComms();
  }

  componentDidUpdate(prevProps) {
    let lastDir = prevProps?.match?.params?.dir;
    let dir = this.props?.match?.params?.dir;
    if (lastDir != dir) {
      this.setState({ dir });
    }
  }

  getMongoMarkets = async () => {
    var options = {
      method: "GET",
      url: "https://quotron.co/mongoMarkets/",
      params: {
        uid: this.state.uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    let mongoMarkets = [];
    await Axios.request(options)
      .then(async (res) => {
        mongoMarkets = res.data?.filter((a) => a);
        this.setState({ mongoMarkets });
      })
      .catch((err) => {
        console.log("mongoMarkets err", err);
      });
  };

  getMongoComms = async () => {
    var options = {
      method: "GET",
      url: "https://quotron.co/mongoComms/",
      params: {
        uid: this.state.uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    let mongoComms = [];
    await Axios.request(options)
      .then(async (res) => {
        mongoComms = res.data;
        let commSymbols = commList()?.map((a) => a.symbol);
        mongoComms = mongoComms?.filter((a) => commSymbols?.includes(a.symbol));
        this.setState({ mongoComms });
      })
      .catch((err) => {
        console.log("mongoComms err", err);
      });
  };

  getMongoStocks = async () => {
    var stockReq = {
      method: "GET",
      url: "https://quotron.co/mongoStocks/",
      params: {
        uid: this.state.uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    let mongoStocks = [];
    await Axios.request(stockReq)
      .then(async (res) => {
        mongoStocks = res.data?.map((a) => {
          return {
            symbol: a.symbol,
            name: a.info?.shortName,
            price: a.price,
            series: a.series,
            info: a.info,
            change: a.change,
            changesPercentage: a.changesPercentage,
          };
        });

        this.setState({ mongoStocks });
      })
      .catch((err) => {
        console.log("mongoStocks err", err);
      });

    return mongoStocks;
  };

  getMongoCrypto = async () => {
    var stockReq = {
      method: "GET",
      url: "https://quotron.co/mongoCrypto/",
      params: {
        uid: this.state.uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    let mongoCrypto = [];
    await Axios.request(stockReq)
      .then(async (res) => {
        mongoCrypto = res.data;
        this.setState({ mongoCrypto: res.data });
      })
      .catch((err) => {
        console.log("mongoCrypto err", err);
      });

    return mongoCrypto;
  };

  loadForexList = () => {
    let forexSearch = forexArr();
    this.setState({ forexSearch });
  };

  loadStockList = async () => {
    let stockList = [];
    let numberOfList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    await Promise.all(
      numberOfList.map(async (a) => {
        return await import(`../data/list/stock${a}.json`).then((list) => {
          stockList = stockList.concat(list.symbols);
          return true;
        });
      })
    );
    stockList = stockList.map((a) => {
      return {
        ...a,
        symbol: a.symbol.replace(" ", "."),
      };
    });
    this.setState({ stockList });
  };

  handleSettingsChange = (checked) => {
    let upDown = "value";
    if (checked) {
      upDown = "percentage";
    }
    let { uid, preferences } = this.state;
    this.togglePrefSettings("upDown", upDown);
    preferences.upDown = upDown;
    this.setState({ preferences });
  };

  handleTempChange = (checked) => {
    let temp = "fahrenheit";
    if (checked) {
      temp = "celcius";
    }
    let { uid, preferences } = this.state;
    this.togglePrefSettings("temp", temp);
    preferences.temp = temp;
    this.setState({ preferences });
  };

  togglePrefSettings = async (type, line) => {
    let { uid, preferences } = this.state;
    var options = {
      method: "POST",
      url: "https://quotron.co/togglePref/",
      data: {
        uid,
        type,
        line,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    let res = await Axios.request(options)
      .then((res) => {
        preferences[type] = line;
        this.setState({ preferences });
        return true;
        //this.getUserData();
      })
      .catch((err) => {
        console.log("togglePref err", err);
        return false;
      });
    return res;
  };

  handleDarkModeChange = (checked) => {
    let lightmode = true;
    if (checked) {
      lightmode = false;
    }
    let { uid, preferences } = this.state;
    let db = getDatabase();
    //onlyMyId
    update(ref(db, "users/" + uid + "/preferences"), { lightmode });
    this.togglePrefSettings("lightmode", lightmode);

    preferences.lightmode = lightmode;
    this.setState({ preferences });
    this.props.cookies?.set("lightmode", lightmode);
  };

  handlePortfolioChange = (checked) => {
    this.setState({ portfolioCrypto: checked });
  };

  finishedResetPassword = (resetPassword) => {
    const auth = getAuth();
    this.setState({
      resetPassword: false,
      showResetComplete: true,
      mode: false,
    });

    let resetPasswordEmail = this.props.cookies.get("resetPasswordEmail");

    signInWithEmailAndPassword(auth, resetPasswordEmail, resetPassword).then((userCredential) => {
      const user = userCredential.user;
      this.props.history.push("/reload");
      this.props.history.go(0);
    });
  };

  checkAdmin = (uid) => {
    var options = {
      method: "GET",
      url: "https://quotron.co/isAdmin/" + uid,
      params: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.request(options)
      .then((res) => {
        if (res.data?.status === true) {
          this.setState({ isAdmin: true });
        }
        if (res.data?.status === "trail") {
          this.setState({ isTrail: true });
        }
      })
      .catch((err) => {
        console.log("isAdmin err", err);
        //this.setState({ networkErr: "Bad Request" });
      });
  };

  getAuth = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      let { mode } = this.state;
      let { oobCode } = this.props;
      if (user) {
        const uid = user.uid;
        if (mode == "verifyEmail" && oobCode) {
          this.verifyEmail();
        }
        this.setState(
          {
            uid,
            user,
            showResetComplete: false,
            resentVerifyEmail: false,
          },
          () => this.getUserData()
        );
        this.checkAdmin(uid);
      } else {
        if (mode == "resetPassword" && oobCode) {
          this.setState({ resetPassword: true });
        }
        let { dir } = this.state;
        if (dir != "setup" && dir != "activate") {
          const externalUrl = "https://shop.quotron.co";
          window.location.href = externalUrl;
        }
        this.setState({ loaded: true });
      }
    });
  };

  verifyEmail = () => {
    console.log("verifyEmail");
    const auth = getAuth();
    let { oobCode, continueUrl } = this.props;
    let reloadUrl = "/reload";
    if (continueUrl) {
      //reloadUrl = "/@" + continueUrl.split("@")[1];
      //reloadUrl = "/@new";
      reloadUrl = continueUrl.split(".co/")[1];
      console.log("continueUrl", continueUrl);
    }
    applyActionCode(auth, oobCode)
      .then((res) => {
        console.log("applyActionCode res", res);
        let { user } = this.state;
        user.reload();
        this.props.history.push(reloadUrl);
        this.props.history.go(0);
      })
      .catch((err) => {
        console.log("applyActionCode err", err);
        this.setState({ verifyEmailError: true });
      });
  };

  sendVerification = () => {
    this.setState({ verifyEmailError: null });
    const auth = getAuth();
    let newDevice = this.props.newDevice || "";
    let actionCodeSettings = {
      url: newDevice ? `https://quotron.co/?newDevice=${newDevice}` : "https://quotron.co/",
    };
    sendEmailVerification(auth.currentUser, actionCodeSettings)
      .then((res) => {
        this.setState({ resentVerifyEmail: true });
      })
      .catch((err) => {
        console.log(" sendEmailVerificationerr", err);
      });
  };

  logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        let exitRoute = "/";

        this.setState({
          uid: null,
          stockData: [],
          stockEodData: [],
          showSettingsMobile: false,
          user: {},
          preferences: {},
          resetPassword: false,
        });

        this.props.history.push(exitRoute);
      })
      .catch((error) => {
        console.log("error logout", error);
      });
  };

  togglePref = (activePref) => {
    let activePrefUpper = this.state.activePrefUpper;
    console.log("before", activePrefUpper, "-", activePref);

    const menu = this.state.preferences?.menu || {};

    for (let item of Object.keys(menu)) {
      if (menu[item].some((pref) => pref.name === activePref)) {
        activePrefUpper = item;
        break;
      }
    }

    if (activePref == "store") {
      activePrefUpper = "store";
    }

    console.log("togglePref activePrefUpper", activePrefUpper, "-", activePref);
    this.setState({ activePref, activePrefUpper, showFiltered: [], networkErr: false });
  };

  togglePrefUpper = (activePrefUpper) => {
    console.log("togglePrefUpper", activePrefUpper, this.state.preferences?.menu);

    let defaultPref = "stocks";
    if (activePrefUpper == "sports") {
      defaultPref = "mlb";
    }
    if (activePrefUpper == "news") {
      defaultPref = "";
    }

    if (activePrefUpper == "store") {
      defaultPref = "store";
    }

    const menu = this.state.preferences?.menu || {};
    let activePref = menu[activePrefUpper]?.[0]?.name || defaultPref;

    if (activePrefUpper == "settings") {
      activePrefUpper = "settings";
      activePref = "devices";
    }

    if (activePref == "ncaa") {
      activePref = "ncaaF";
    }

    console.log("after", activePrefUpper, activePref);
    this.setState({
      activePrefUpper,
      activePref,
      showFiltered: [],
      networkErr: false,
    });
  };

  toggleSettingsMobile = () => {
    this.setState({ showSettingsMobile: !this.state.showSettingsMobile });
  };

  openSettings = () => {
    this.setState({ activePrefUpper: "settings" }, () => {
      this.toggleSettingsMobile();
    });
  };

  openSports = () => {
    this.setState({ activePref: "nfl" }, () => {
      this.toggleSettingsMobile();
    });
  };

  updateUsername = (username) => {
    this.setState({ username });
  };

  openProfile = () => {
    this.setState({ activePref: "profile" }, () => {
      this.toggleSettingsMobile();
    });
  };

  openAdmin = () => {
    this.setState({ activePref: "admin", activePrefUpper: "" }, () => {
      this.toggleSettingsMobile();
    });
  };

  setError = (string) => {
    this.setState({ deviceOutput: string }, () => {
      setTimeout(() => {
        this.setState({ deviceOutput: "" });
      }, 5000);
    });
  };

  setDeviceId = (newDevice, uid) => {
    return new Promise((resolve, reject) => {
      Axios.get(`https://quotron.co/setDeviceId/${newDevice}/${uid}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject("nop"));
    });
  };

  deviceCheck = (newDevice, uid) => {
    return new Promise((resolve, reject) => {
      Axios.get(`https://quotron.co/deviceCheck/${newDevice}/${uid}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject("nop"));
    });
  };

  getWeather = async (obj) => {
    let weatherArr = obj ? Object.values(obj) : [];

    let allPromises = weatherArr.map(async (a) => {
      return Axios.request({
        method: "GET",
        url: `https://quotron.co/weather/` + a.postal,
      })
        .then((res) => res.data[0])
        .catch((err) => null);
    });

    let results = await Promise.allSettled(allPromises);
    let weatherData = results
      .filter((result) => result.status === "fulfilled" && result.value !== null && result.value)
      .map((result) => result.value);

    weatherData = weatherData?.map((a) => {
      return {
        ...a,
        inactive: weatherArr?.filter((b) => b.latLong == a.latLong)[0]?.inactive || false,
        city: weatherArr?.filter((b) => b.latLong == a.latLong)[0]?.city || a.data?.location?.name,
      };
    });

    this.setState({ weatherData });
  };

  formatData = (data) => {
    let { uid, loaded } = this.state;
    let { newDevice } = this.props;
    let { stock, device, preferences, crypto, forex, markets, portfolio, favTeams, invites, watch, comms, weather } = data;

    //if no menu -- default first 300
    console.log("formatData prefs", preferences);
    if (!preferences.menu) {
      preferences["menu"] = this.state.preferences?.menu;
    }

    console.log("format menu", preferences.menu);
    //if there is preferences.menu.finance, array length >0, take the first item
    let firstItem = null;
    let firstItemKey = null;
    for (let key in preferences.menu) {
      if (preferences.menu[key] && preferences.menu[key].length > 0) {
        firstItem = preferences.menu[key][0];
        firstItemKey = key;
        break;
      }
    }
    console.log("firstItem", firstItem, firstItemKey);

    let activePrefUpper = firstItemKey || "finance";
    let activePref = firstItem?.name || "stocks";

    if (!firstItem && !firstItemKey) {
      activePrefUpper = "store";
    }

    if (loaded) {
      activePrefUpper = this.state.activePrefUpper || firstItemKey || "finance";
      activePref = this.state.activePref || firstItem.name || "stocks";
    }

    console.log("activePrefUpper", activePrefUpper, activePref);

    let deviceArr = device ? Object.values(device) : [];
    let watchNFL = watch?.football ? Object.values(watch?.football) : [];
    let watchNBA = watch?.basketball ? Object.values(watch?.basketball) : [];
    let watchNHL = watch?.hockey ? Object.values(watch?.hockey) : [];
    let watchMLB = watch?.baseball ? Object.values(watch?.baseball) : [];
    let watchNCAAF = watch?.NCAAF ? Object.values(watch?.NCAAF) : [];
    let watchNCAAB = watch?.NCAAB ? Object.values(watch?.NCAAB) : [];
    let watchNCAAH = watch?.NCAAH ? Object.values(watch?.NCAAH) : [];

    console.log("deviceArr", deviceArr);

    let favTeamsNFL = favTeams?.NFL
      ? Object.entries(favTeams?.NFL).map(([key, value]) => {
          return { ...value, team: key };
        })
      : [];

    let favTeamsNBA = favTeams?.NBA
      ? Object.entries(favTeams?.NBA).map(([key, value]) => {
          return { ...value, team: key };
        })
      : [];

    let favTeamsNHL = favTeams?.NHL
      ? Object.entries(favTeams?.NHL).map(([key, value]) => {
          return { ...value, team: key };
        })
      : [];

    let favTeamsNCAAF = favTeams?.NCAAF
      ? Object.entries(favTeams?.NCAAF).map(([key, value]) => {
          return { ...value, team: key };
        })
      : [];

    let favTeamsNCAAB = favTeams?.NCAAB
      ? Object.entries(favTeams?.NCAAB).map(([key, value]) => {
          return { ...value, team: key };
        })
      : [];

    let preferencesObj = preferences
      ? preferences
      : {
          upDown: "value",
        };

    console.log("preferencesObj", preferencesObj);

    if (preferences?.lightmode) {
      this.props.cookies?.set("lightmode", true);
    }

    this.setState({
      loaded: true,
      preferences: preferencesObj,
      deviceArr,
      watchNFL,
      watchNBA,
      watchNHL,
      watchMLB,
      watchNCAAF,
      watchNCAAB,
      watchNCAAH,
      favTeamsNFL,
      favTeamsNBA,
      favTeamsNHL,
      favTeamsNCAAF,
      favTeamsNCAAB,
      activePrefUpper,
      activePref,
    });

    if (stock) {
      this.stockGet(stock);
    }

    if (forex) {
      this.forexGet(forex);
    }

    if (crypto) {
      this.cryptoGet(crypto);
    }

    if (weather) {
      this.getWeather(weather);
    }

    if (markets) {
      let marketArr = Object.values(markets);
      marketArr = marketArr.map((a) => {
        if (typeof a === "string" || a instanceof String) {
          return a?.replace(".", "-");
        }
      });
      this.setState({ hiddenMarkets: marketArr });
    }
    if (comms) {
      let commArr = Object.values(comms);
      commArr = commArr.map((a) => (a.inactive ? a.name : null));
      this.setState({ hiddenComms: commArr });
    }
  };

  getUserData = async () => {
    let { uid } = this.state;
    let { newDevice } = this.props;

    console.log("getUserData");

    //new get from mongo
    var options = {
      method: "GET",
      url: "https://quotron.co/user/" + uid,

      headers: {
        "Content-Type": "application/json",
      },
    };
    let data = await Axios.request(options)
      .then((res) => {
        if (res.data?.length > 0) {
          return res.data[0];
        }
      })
      .catch((err) => {
        console.log("user err", err);
      });

    this.formatData(data);
    this.loadStockList();
    this.loadForexList();

    //already logged in- adding new device
    let emailVerified = this.state.user?.emailVerified;
    if (newDevice && emailVerified) {
      let formatDevice = newDevice?.replaceAll("-", "");
      formatDevice = formatDevice?.replace(/(\w{4})(\w{3})(\w{3})(\w{3})(\w{1,4})/, "$1-$2-$3-$4-$5");
      let deviceCorrect = await this.deviceCheck(formatDevice, uid);
      if (deviceCorrect.id) {
        if (!deviceCorrect.account) {
          //register device
          let deviceReg = await this.setDeviceId(formatDevice, uid);
          this.setError("Device successfully registered", deviceReg);
        }
      } else {
        this.setError("Device not found in database");
      }
    }
  };

  sortPercA = (a, b) => a.price_change_percentage_24h_in_currency - b.price_change_percentage_24h_in_currency;

  sortPercD = (a, b) => b.price_change_percentage_24h_in_currency - a.price_change_percentage_24h_in_currency;

  sortMkt = (a, b) => a.market_cap - b.market_cap;

  sortMktD = (a, b) => b.market_cap - a.market_cap;

  alternateSort = (type) => {
    if (type == "crypto") {
      let { cryptoData, cryptoSort } = this.state;

      if (cryptoSort == "mktD") {
        cryptoData.sort(this.sortMkt);
        this.setState({ cryptoData, cryptoSort: "mktA" });
      }
      if (cryptoSort == "mktA") {
        cryptoData.sort(this.sortPercD);
        this.setState({ cryptoData, cryptoSort: "percD" });
      }
      if (cryptoSort == "percD") {
        cryptoData.sort(this.sortPercA);
        this.setState({ cryptoData, cryptoSort: "percA" });
      }
      if (cryptoSort == "percA") {
        cryptoData.sort(this.sortMktD);
        this.setState({ cryptoData, cryptoSort: "mktD" });
      }
    }
  };

  onCryptoInput = (event) => {
    let cryptoInput = event.target.value;
    this.setState({ cryptoInput });
  };

  onForexInput = (event) => {
    let forexInput = event.target.value;
    let forexLower = forexInput.toLowerCase().replace(" ", "");
    let forexSlash = forexLower.replace("/", "");

    let forexFilter = this.state.forexSearch.filter(
      (a) => a.symbol.toLowerCase().startsWith(forexSlash) || a.name.toLowerCase().includes(forexLower)
    );

    forexLower = forexLower.replace("/", "");
    this.setState({ forexInput, networkErr: false, forexFilter });
  };

  onPortfolioInput = (event) => {
    let stockInput = event.target.value;
    let stockLower = stockInput.toLowerCase();
    this.setState({ portfolioInput: stockInput, networkErr: false });

    let showFiltered = [];
    if (stockInput.length >= 3) {
      //do search
      // prettier-ignore
      let filteredList = this.state.stockList.filter((a) =>
        a.name.toLowerCase().includes(stockLower)
      );

      if (filteredList.length > 5) {
        stockLower = stockLower.replace(" ", "");
        // prettier-ignore
        let regrex = new RegExp('(?:^|\\s)' + stockLower + '(?:$|\\s)', "g");
        filteredList = this.state.stockList.filter((a) => regrex.test(a.name.toLowerCase()));
      }
      showFiltered = showFiltered.concat(filteredList);
      if (showFiltered.length > 9) {
        showFiltered = [];
      }
    }
    let exactSymbol = this.state.stockList.filter((a) => a.symbol.toLowerCase() == stockLower);
    showFiltered = exactSymbol.concat(showFiltered);
    this.setState({ showFiltered });
  };

  onPortfolioInputCrypto = (event) => {
    let portfolioInputCrypto = event.target.value;
    this.setState({ portfolioInputCrypto });
  };

  onPortfolioSubmit = (event, portfolioName) => {
    event.preventDefault();
    let { portfolioInput } = this.state;
    if (portfolioInput == "") return;
    this.onStockSubmit("", portfolioInput, portfolioName);
  };

  onPortfolioSubmitCrypto = (event) => {
    event.preventDefault();
    this.onCryptoSubmit("", this.state.portfolioInputCrypto);
  };

  makePortfolio = (name) => {
    event.preventDefault();
    if (name == "" || !name) return;
    let db = getDatabase();
  };

  updatePortfolio = (stock, portfolio, shares, cost, date) => {
    let db = getDatabase();
    let showDate = new Date().getTime();
    //onlyMyId
    set(ref(db, "users/" + this.state.uid + `/portfolio/` + portfolio + `/stock/` + stock + `/trades/` + showDate), { shares, cost, date });
  };

  onCryptoSubmit = (event, portfolio = false) => {
    event && event.preventDefault();
    let { cryptoInput } = this.state;
    if (portfolio) {
      cryptoInput = this.state.portfolioInputCrypto;
    }
    this.setState({ networkErr: false });
    let upperCrypto = cryptoInput.toUpperCase();
    upperCrypto = upperCrypto.replace(".", "~");

    let top1000 = this.state.mongoCrypto;
    console.log("top1000", top1000);
    let isIn = false;
    top1000 = top1000?.filter((a) => a.symbol == upperCrypto);
    console.log("after", top1000, upperCrypto);
    if (top1000?.length > 0) {
      this.addToUser("crypto", upperCrypto);
      this.setState({ cryptoInput: "" });
    } else {
      this.setState({ networkErr: "Not in top 1000" });
    }
  };

  addToUser = (type, name) => {
    let { uid } = this.state;
    var options = {
      method: "POST",
      url: "https://quotron.co/addToUser/",
      data: {
        uid,
        type,
        name,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options)
      .then((res) => {
        console.log("addToUser res", res);
        this.setState({ loadingStock: false });
        this.getUserData();
      })
      .catch((err) => {
        console.log("addToUser err", err);
      });
  };

  //stocks
  onStockInput = (event) => {
    let stockInput = event.target.value;
    let stockLower = stockInput.toLowerCase();
    this.setState({ stockInput });
    if (stockLower?.length < 1) {
      console.log("<1");
    }

    let showFiltered = [];
    let { stockList, mongoStocks } = this.state;

    stockList = stockList.concat(mongoStocks);

    if (stockInput.length >= 1) {
      //do search
      // prettier-ignore
      let filteredList = stockList.filter((a) =>
        a.name?.toLowerCase().includes(stockLower) || a.symbol?.toLowerCase().includes(stockLower)
      );
      //console.log("filteredList", filteredList);

      if (filteredList.length > 5) {
        stockLower = stockLower.replace(" ", "");
        // prettier-ignore
        let regrex = new RegExp('(?:^|\\s)' + stockLower + '(?:$|\\s)', "g");
        filteredList = stockList.filter((a) => regrex.test(a.name?.toLowerCase()));
      }
      showFiltered = showFiltered.concat(filteredList);
      if (showFiltered.length > 9) {
        showFiltered = [{ symbol: filteredList.length, name: "results" }];
      }
    }
    let exactSymbol = stockList.filter((a) => a.symbol?.toLowerCase() == stockLower);
    showFiltered = exactSymbol.concat(showFiltered);

    //remove duplicates
    showFiltered = showFiltered.reduce((acc, current) => {
      const x = acc.find((item) => item.symbol === current.symbol);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    let notThere = true;
    showFiltered.map((a) => {
      if (a?.symbol?.toLowerCase() == stockLower) {
        notThere = false;
      }
    });
    if (notThere && stockLower?.length > 0) {
      showFiltered = showFiltered.concat([{ symbol: stockLower?.toUpperCase(), name: "" }]);
    }

    this.setState({ showFiltered });
  };

  onPressStockFilter = (symbol, name, portfolio = false) => {
    if (name == "results") return;
    this.setState({ stockInput: symbol, showFiltered: [] }, () => {
      this.onStockSubmit();
    });
  };

  onForexSubmit = (event = "", forexInput = this.state.forexInput) => {
    this.setState({ networkErr: false, loadingForex: true });
    event && event.preventDefault();
    let upperForex = forexInput.replace("/", "");
    upperForex = upperForex.toUpperCase();

    //new through backend
    var options = {
      method: "GET",
      url: "https://quotron.co/newForex/" + upperForex,
      params: {
        uid: this.state.uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.request(options)
      .then((res) => {
        let name = res.data?.symbol;

        this.addToUser("forex", name);
        this.setState({ forexInput: "", loadingForex: false, forexFilter: [] });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ loadingForex: false, networkErr: "Bad Request" });
      });
  };

  onStockSubmit = async (event = "", portfolio = false, portfolioName = null, type = null) => {
    console.log("stock submit", portfolio, portfolioName, type);
    let { stockInput } = this.state;
    event && event.preventDefault();

    this.setState({ networkErr: false, loadingStock: true });
    if (this.state.stockData?.length > 80) {
      this.setState({ networkErr: "80 limit" });
      return;
    }

    let upperStock = stockInput.toUpperCase();
    if (portfolio) {
      upperStock = portfolio.toUpperCase();
    }

    upperStock = upperStock.replaceAll(" ", "");
    upperStock = upperStock.replaceAll(".", "-");

    if (upperStock?.length < 1) return;

    this.setState({ justAdded: upperStock });

    //if stock already in DB
    let db = getDatabase();
    let isInDB = null;

    var optionsCheck = {
      method: "GET",
      url: "https://quotron.co/getStock/" + upperStock,
      params: {
        uid: this.state.uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    await Axios.request(optionsCheck).then((res) => {
      if (res.data?.length > 0) {
        isInDB = true; //res.data[0]
        console.log("is in db true", upperStock);
      }
    });

    if (isInDB) {
      this.addToUser("stock", upperStock);
      this.setState({ loadingStock: false, showFiltered: [], stockInput: "" });
      return;
    }

    var options = {
      method: "GET",
      url: "https://quotron.co/newStockName/" + upperStock,
      params: {
        uid: this.state.uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.request(options)
      .then((res) => {
        console.log("quotron.co/newStockName res", res);

        upperStock = res.data?.formattedSymbol;
        this.setState({ justAdded: upperStock });

        let name = upperStock.replace(".", "-");
        this.setState({ stockInput: "" });
        if (!res.data.price) {
          this.setState({ networkErr: "Bad Request", loadingStock: false });
          return;
        }
        this.getNewEod(name, portfolioName, upperStock, res.data.price);
      })
      .catch((err) => {
        console.log("err", err);
        //this.setState({ networkErr: "Bad Request" });
      });
  };

  getNewEod = async (symbol, portfolioName = false, upperStock = null, price) => {
    console.log("getNewEod", symbol, portfolioName, upperStock);
    //multiple datas once stock in db
    var options = {
      method: "GET",
      url: "https://quotron.co/newStockEod/" + symbol,
      params: {
        uid: this.state.uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    var optionsNames = {
      method: "GET",
      url: "https://quotron.co/newStockName/" + symbol,
      params: {
        uid: this.state.uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    let series = {};
    await Axios.request(options).then(async (res) => {
      series = res.data;
      if (series["newPrice"]) {
        price = series["newPrice"];
      }
    });

    let info = {};
    await Axios.request(optionsNames).then(async (res) => {
      info = res.data;
      console.log("optionsNames", info);
    });

    let charts = {};

    let stockData = this.state.stockData;
    if (portfolioName) {
      stockData = this.state.portfolioData;
    }
    stockData.push({ charts, info, series, symbol, price, timestamp: 123 });

    stockData = stockData.sort((a, b) => {
      if (a.symbol < b.symbol) return -1;
      if (a.symbol > b.symbol) return 1;
      return 0;
    });

    if (portfolioName) {
      this.setState({ portfolioData: stockData, showFiltered: [] });
    } else {
      stockData.map((a, k) => {
        if (a.symbol == this.state.justAdded) {
          this.setState({
            activeStock: this.props.isMobile ? -1 : k,
            justAdded: false,
          });
        }
      });
      console.log("adding new stockData", stockData);
      this.setState({ stockData, showFiltered: [] });
    }

    let db = getDatabase();

    this.addToUser("stock", upperStock);

    return "ok";
  };

  cryptoGet = async (cryptos) => {
    let cryptoArr = Object.values(cryptos);
    cryptoArr = cryptoArr.map((a) => a.name);

    let mongoCrypto = this.state.mongoCrypto;
    if (mongoCrypto?.length < 1) {
      mongoCrypto = await this.getMongoCrypto();
    }

    let cryptoData = mongoCrypto?.filter((a) => cryptoArr.includes(a.symbol));
    cryptoData = cryptoData.map((a) => {
      return { ...a, inactive: cryptos[a.symbol]?.inactive || false };
    });
    cryptoData.sort(this.sortMktD);
    this.setState({ cryptoData });
  };

  //might be better to just 1 call all stocks and then filter
  stockGet = async (stock, portfolio = false) => {
    let stockArr = Object.values(stock);
    stockArr = stockArr.map((a) => a.name);

    let mongoStocks = this.state.mongoStocks;

    //it was added new- get from stockData & add to mongoStocks
    let justAddedRow = this.state.stockData?.filter((a) => a.timestamp == 123);

    if (mongoStocks?.length < 1) {
      mongoStocks = await this.getMongoStocks();
    }

    //console.log("stockGet-1", stock, mongoStocks);

    let stockData = mongoStocks?.filter((a) => stockArr.includes(a.symbol));

    if (justAddedRow?.length > 0) {
      stockData = stockData.concat(justAddedRow);
    }

    stockData = stockData.sort((a, b) => {
      if (a.symbol < b.symbol) return -1;
      if (a.symbol > b.symbol) return 1;
      return 0;
    });

    if (!portfolio) {
      stockData.map(async (a, k) => {
        if (a.symbol == this.state.justAdded) {
          this.setState({
            activeStock: this.props.isMobile ? -1 : k,
            justAdded: false,
          });
        }
      });
    }

    stockData = stockData.map((a) => {
      return { ...a, inactive: stock[a.symbol]?.inactive || false };
    });

    if (portfolio) {
      this.setState({ portfolioStocks: stockData });
    } else {
      this.setState({ stockData });
    }
  };

  forexGet = async (forex) => {
    let { uid } = this.state;
    let forexArr = Object.values(forex);
    let forexData = [];
    forexData = await Axios.get(`https://quotron.co/getForex/${uid}`).then((res) => res.data);

    forexData = forexData.filter((a) => a);

    forexData = forexData
      .filter((item2) => forexArr.some((item1) => item1.name === item2.name))
      .map((item) => {
        const inactiveItem = forexArr.find((item1) => item1.name === item.name);
        return { ...item, inactive: inactiveItem.inactive };
      });

    forexData = forexData.sort((a, b) => a.name.localeCompare(b.name));

    this.setState({ forexData });
  };

  makeMarketActive = (k) => {
    this.setState({ activeMarket: k });
  };

  makeStockActive = (k) => {
    this.setState({ activeStock: k });
  };

  makePortfolioActive = (k) => {
    this.setState({ activePortfolio: k });
  };

  makeForexActive = (k) => {
    this.setState({ activeForex: k });
  };

  makeCryptoActive = (k) => {
    this.setState({ activeCrypto: k });
  };

  toggleWeather = (postal, inactive) => {
    let { uid } = this.state;
    var options = {
      method: "POST",
      url: "https://quotron.co/toggleWeather/",
      //url: "http://localhost:80/toggleWeather/",
      data: {
        uid,
        postal,
        inactive,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options)
      .then((res) => {
        let weatherArr = this.state.weatherData?.map((a) => {
          if (a.postal == postal) {
            return { ...a, inactive: !inactive };
          }
          return a;
        });
        this.setState({ weatherData: weatherArr });
      })
      .catch((err) => {
        console.log("toggleWeather err", err);
      });
  };

  toggleGame = async (sport, id, setId, allGames = false) => {
    console.log("toggleGame", id);
    let watchArr = [];
    if (sport == "basketball") {
      watchArr = this.state.watchNBA;
    }
    if (sport == "baseball") {
      watchArr = this.state.watchMLB;
    }
    if (sport == "football") {
      watchArr = this.state.watchNFL;
    }
    if (sport == "hockey") {
      watchArr = this.state.watchNHL;
    }
    if (sport == "NCAAF") {
      watchArr = this.state.watchNCAAF;
    }
    if (sport == "NCAAB") {
      watchArr = this.state.watchNCAAB;
    }
    if (sport == "NCAAH") {
      watchArr = this.state.watchNCAAH;
    }
    let { uid } = this.state;
    var options = {
      method: "POST",
      url: "https://quotron.co/toggleGame/",
      data: {
        uid,
        sport,
        id,
        setId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options)
      .then((res) => {
        //this.getUserData();
        let notThere = true;
        watchArr = watchArr.map((a) => {
          if (a == id || a == id + 0.5) {
            notThere = false;
            return setId;
          } else {
            return a;
          }
        });
        if (notThere) {
          watchArr = watchArr.concat([id]);
        }
        watchArr = watchArr?.filter((a) => a != "");
        //setstate
        if (sport == "basketball") {
          this.setState({ watchNBA: watchArr });
        }
        if (sport == "baseball") {
          this.setState({ watchMLB: watchArr });
        }
        if (sport == "football") {
          this.setState({ watchNFL: watchArr });
        }
        if (sport == "hockey") {
          this.setState({ watchNHL: watchArr });
        }
        if (sport == "NCAAF") {
          this.setState({ watchNCAAF: watchArr });
        }
        if (sport == "NCAAB") {
          this.setState({ watchNCAAB: watchArr });
        }
        if (sport == "NCAAH") {
          this.setState({ watchNCAAH: watchArr });
        }
      })
      .catch((err) => {
        console.log("toggleGame err", err);
      });
  };

  removeCrypto = (symbol) => {
    let db = getDatabase();
    let { cryptoData } = this.state;
    //delete cryptoData[symbol];
    this.setState({ cryptoData, activeCrypto: -1 });
    this.removeFromUser("crypto", symbol);
  };

  removePortfolio = (name) => {
    let db = getDatabase();
  };

  removeStock = async (stock) => {
    let db = getDatabase();
    let formattedSymbol = stock.replace(".", "-");
    this.setState({ activeStock: -1 });
    this.removeFromUser("stock", formattedSymbol);
  };

  removeFromUser = (type, name) => {
    let { uid } = this.state;
    var options = {
      method: "POST",
      url: "https://quotron.co/removeFromUser/",
      data: {
        uid,
        type,
        name,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options)
      .then((res) => {
        console.log("removeFromUser res", res);
        this.getUserData();
      })
      .catch((err) => {
        console.log("removeFromUser err", err);
      });
  };

  inactiveForex = async (forex, inactive) => {
    let toggleGood = await this.toggleOn("forex", forex, !inactive);
    if (toggleGood == "no") return;
    let { forexData } = this.state;
    console.log("forexData", forexData);
    forexData = forexData?.map((a) => {
      return {
        ...a,
        inactive: a.name == forex ? !inactive : a.inactive,
      };
    });
    this.setState({ forexData });
  };

  inactiveStock = async (stock, inactive) => {
    let formattedSymbol = stock.replace(".", "-");
    let toggleGood = await this.toggleOn("stock", formattedSymbol, !inactive);
    if (toggleGood == "no") return;
    let { stockData } = this.state;
    stockData = stockData?.map((a) => {
      return {
        ...a,
        inactive: a.symbol == formattedSymbol ? !inactive : a.inactive,
      };
    });
    this.setState({ stockData });
  };

  toggleOn = async (type, name, inactive) => {
    let { uid } = this.state;
    var options = {
      method: "POST",
      url: type == "markets" ? "https://quotron.co/toggleMarket/" : "https://quotron.co/toggle/",
      data: {
        uid,
        type,
        name,
        inactive,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      Axios.request(options)
        .then((res) => {
          console.log("toggle res", res);
          resolve("yes");
          //this.getUserData();
        })
        .catch((err) => {
          reject("no");
          console.log("toggle err", err);
        });
    });
  };

  inactiveMarket = async (market, inactive) => {
    market = market.replace(".", "-");
    let toggleGood = await this.toggleOn("markets", market, inactive ? null : market);
    if (toggleGood == "no") return;
    let { hiddenMarkets } = this.state;
    let notThere = true;
    hiddenMarkets = hiddenMarkets.map((a) => {
      if (a == market) {
        notThere = false;
        return false;
      } else {
        return a;
      }
    });
    if (notThere) {
      hiddenMarkets = hiddenMarkets.concat([market]);
    }
    this.setState({ hiddenMarkets });
  };

  inactiveComm = async (comm, inactive) => {
    comm = comm.replace(".", "-");
    let toggleGood = await this.toggleOn("comms", comm, inactive ? comm : null);
    let { hiddenComms } = this.state;
    let notThere = true;
    hiddenComms = hiddenComms.map((a) => {
      if (a == comm) {
        notThere = false;
        return false;
      } else {
        return a;
      }
    });
    if (notThere) {
      hiddenComms = hiddenComms.concat([comm]);
    }
    this.setState({ hiddenComms });
  };

  inactiveCrypto = async (crypto, inactive) => {
    let formattedSymbol = crypto.replace(".", "-");
    let toggleGood = await this.toggleOn("crypto", formattedSymbol, !inactive);
    if (toggleGood == "no") return;
    let { cryptoData } = this.state;
    cryptoData = cryptoData?.map((a) => {
      return {
        ...a,
        inactive: a.symbol == formattedSymbol ? !inactive : a.inactive,
      };
    });
    this.setState({ cryptoData });
  };

  removeForex = (forex) => {
    forex = forex.replace("/", "");
    this.setState({ activeForex: -1 });
    this.removeFromUser("forex", forex);
  };

  chartTime = (time) => {
    console.log("chartTime", time);
  };

  makeTeamActive = (k) => {
    this.setState({ activeTeam: k, activeTeamFav: -1 });
  };

  makeTeamActiveFav = (k) => {
    let nextFav = k;
    if (this.state.activeTeamFav == k) {
      nextFav = -1;
    }
    this.setState({ activeTeamFav: nextFav, activeTeam: -1 });
  };

  onFootballSubmit = (event = null) => {
    event && event.preventDefault();
  };

  setSplashPage = (splashPage) => {
    this.setState({ splashPage });
  };

  updateFavTeams = (favAfter, name) => {
    let stateName = `favTeams${name}`;
    this.setState({ [stateName]: favAfter });
  };

  render() {
    let {
      splashPage,
      mode,
      uid,
      activePref,
      activePrefUpper,
      networkErr,
      showSettingsMobile,
      pageWidth,
      deviceOutput,
      loaded,
      resetPassword,
      showResetComplete,
      cryptoData,
      cryptoInput,
      activeCrypto,
      cryptoSort,
      forexData,
      forexInput,
      activeForex,
      markets,
      activeMarket,
      stockData,
      stockInput,
      activeStock,
      showFiltered,
      preferences,
      deviceArr,
      hiddenMarkets,
      verifyEmailError,
      portfolioData,
      portfolioCrypto,
      portfolioInput,
      portfolioInputCrypto,
      activePortfolio,
      portfolioStocks,
      dir,
      isAdmin,
      username,
      justAdded,
      activeTeam,
      activeTeamFav,
      footballInput,
      watchNFL,
      watchNBA,
      watchNHL,
      watchMLB,
      watchNCAAF,
      watchNCAAB,
      watchNCAAH,
      loadingStock,
      loadingForex,
      forexFilter,
      mongoStocks,
      mongoMarkets,
      isTrail,
    } = this.state;

    let { isMobile, newDevice, oobCode, invite, step } = this.props;

    let darkmode = !preferences?.lightmode;

    let activePrefBox = <div className={"prefBox"} />;

    if (activePref == "ncaa") {
      activePrefBox = <ListNew uid={uid} darkmode={darkmode} />;
    }

    if (activePref == "nfl") {
      activePrefBox = (
        <ListSport
          key={"NFL"}
          uid={uid}
          favTeams={this.state.favTeamsNFL || []}
          updateFavTeams={this.updateFavTeams}
          sport={"football"}
          name={"NFL"}
          watchGames={watchNFL}
          darkmode={darkmode}
          isMobile={isMobile}
          preferences={preferences}
          toggleGame={this.toggleGame}
          togglePrefSettings={this.togglePrefSettings}
          isAdmin={isAdmin}
          togglePref={this.togglePref}
          activePref={this.state.activePref}
        />
      );
    }

    if (activePref == "ncaaF") {
      activePrefBox = (
        <ListSport
          key={"NCAAF"}
          uid={uid}
          favTeams={this.state.favTeamsNCAAF || []}
          updateFavTeams={this.updateFavTeams}
          sport={"NCAAF"}
          name={"NCAAF"}
          watchGames={watchNCAAF}
          darkmode={darkmode}
          isMobile={isMobile}
          preferences={preferences}
          toggleGame={this.toggleGame}
          togglePrefSettings={this.togglePrefSettings}
          isAdmin={isAdmin}
          togglePref={this.togglePref}
          activePref={this.state.activePref}
        />
      );
    }

    if (activePref == "ncaaB") {
      activePrefBox = (
        <ListSport
          key={"NCAAB"}
          uid={uid}
          favTeams={this.state.favTeamsNCAAB || []}
          updateFavTeams={this.updateFavTeams}
          sport={"NCAAB"}
          name={"NCAAB"}
          watchGames={watchNCAAB}
          darkmode={darkmode}
          isMobile={isMobile}
          preferences={preferences}
          toggleGame={this.toggleGame}
          togglePrefSettings={this.togglePrefSettings}
          isAdmin={isAdmin}
          togglePref={this.togglePref}
          activePref={this.state.activePref}
        />
      );
    }

    if (activePref == "ncaaH") {
      activePrefBox = (
        <ListSport
          key={"NCAAH"}
          uid={uid}
          favTeams={[]}
          updateFavTeams={this.updateFavTeams}
          sport={"NCAAH"}
          name={"NCAAH"}
          watchGames={watchNCAAH}
          darkmode={darkmode}
          isMobile={isMobile}
          preferences={preferences}
          toggleGame={this.toggleGame}
          togglePrefSettings={this.togglePrefSettings}
          isAdmin={isAdmin}
          togglePref={this.togglePref}
          activePref={this.state.activePref}
        />
      );
    }

    if (activePref == "nba") {
      activePrefBox = (
        <ListSport
          key={"NBA"}
          uid={uid}
          favTeams={this.state.favTeamsNBA || []}
          updateFavTeams={this.updateFavTeams}
          sport={"basketball"}
          name={"NBA"}
          watchGames={watchNBA}
          darkmode={darkmode}
          isMobile={isMobile}
          preferences={preferences}
          toggleGame={this.toggleGame}
          togglePrefSettings={this.togglePrefSettings}
          isAdmin={isAdmin}
          togglePref={this.togglePref}
          activePref={this.state.activePref}
        />
      );
    }

    if (activePref == "mlb") {
      activePrefBox = (
        <ListSport
          key={"MLB"}
          uid={uid}
          favTeams={[]}
          updateFavTeams={this.updateFavTeams}
          sport={"baseball"}
          name={"MLB"}
          watchGames={watchMLB}
          darkmode={darkmode}
          isMobile={isMobile}
          preferences={preferences}
          toggleGame={this.toggleGame}
          togglePrefSettings={this.togglePrefSettings}
          isAdmin={isAdmin}
          togglePref={this.togglePref}
          activePref={this.state.activePref}
        />
      );
    }

    if (activePref == "nhl") {
      activePrefBox = (
        <ListSport
          key={"NHL"}
          uid={uid}
          favTeams={this.state.favTeamsNHL || []}
          updateFavTeams={this.updateFavTeams}
          sport={"hockey"}
          name={"NHL"}
          watchGames={watchNHL}
          darkmode={darkmode}
          isMobile={isMobile}
          preferences={preferences}
          toggleGame={this.toggleGame}
          togglePrefSettings={this.togglePrefSettings}
          isAdmin={isAdmin}
          togglePref={this.togglePref}
          activePref={this.state.activePref}
        />
      );
    }

    if (activePref == "portfolio") {
      activePrefBox = (
        <ListPortfolio
          showFiltered={this.state.showFiltered}
          onPortfolioInput={this.onPortfolioInput}
          onPortfolioInputCrypto={this.onPortfolioInputCrypto}
          portfolioInput={portfolioInput}
          portfolioInputCrypto={portfolioInputCrypto}
          onPortfolioSubmit={this.onPortfolioSubmit}
          onPortfolioSubmitCrypto={this.onPortfolioSubmitCrypto}
          isMobile={isMobile}
          handlePortfolioChange={this.handlePortfolioChange}
          portfolioCrypto={portfolioCrypto}
          networkErr={networkErr}
          portfolioData={portfolioData}
          cryptoData={cryptoData}
          stockData={stockData}
          preferences={preferences}
          activePortfolio={activePortfolio}
          makePortfolioActive={this.makePortfolioActive}
          removePortfolio={this.removePortfolio}
          makePortfolio={this.makePortfolio}
          updatePortfolio={this.updatePortfolio}
          portfolioStocks={portfolioStocks}
          onPressStockFilter={this.onPressStockFilter}
          uid={uid}
          darkmode={darkmode}
        />
      );
    }

    if (activePref == "crypto") {
      activePrefBox = (
        <ListCrypto
          cryptoData={cryptoData}
          cryptoInput={cryptoInput}
          activeCrypto={activeCrypto}
          removeCrypto={this.removeCrypto}
          makeCryptoActive={this.makeCryptoActive}
          onCryptoSubmit={this.onCryptoSubmit}
          onCryptoInput={this.onCryptoInput}
          alternateSort={this.alternateSort}
          cryptoSort={cryptoSort}
          darkmode={darkmode}
          inactiveCrypto={this.inactiveCrypto}
          isMobile={isMobile}
        />
      );
    }

    if (activePref == "markets") {
      activePrefBox = (
        <ListMarkets
          markets={mongoMarkets}
          makeMarketActive={this.makeMarketActive}
          activeMarket={activeMarket}
          hiddenMarkets={hiddenMarkets}
          darkmode={darkmode}
          inactiveMarket={this.inactiveMarket}
          isMobile={isMobile}
          preferences={preferences}
        />
      );
    }

    if (activePref == "comms") {
      activePrefBox = (
        <ListComms
          markets={this.state.mongoComms}
          darkmode={darkmode}
          isMobile={isMobile}
          preferences={preferences}
          inactiveComm={this.inactiveComm}
          hiddenComms={this.state.hiddenComms}
        />
      );
    }

    if (activePref == "stocks") {
      activePrefBox = (
        <ListStocks
          chartTime={this.chartTime}
          stockData={stockData}
          stockInput={stockInput}
          activeStock={activeStock}
          networkErr={networkErr}
          makeStockActive={this.makeStockActive}
          removeStock={this.removeStock}
          onStockSubmit={this.onStockSubmit}
          onStockInput={this.onStockInput}
          alternateSort={this.alternateSort}
          showFiltered={showFiltered}
          onPressStockFilter={this.onPressStockFilter}
          preferences={preferences}
          isMobile={isMobile}
          handleSettingsChange={this.handleSettingsChange}
          darkmode={darkmode}
          inactiveStock={this.inactiveStock}
          loadingStock={loadingStock}
        />
      );
    }

    if (activePref == "forex") {
      activePrefBox = (
        <ListForex
          forexData={forexData}
          forexInput={forexInput}
          activeForex={activeForex}
          networkErr={networkErr}
          makeForexActive={this.makeForexActive}
          removeForex={this.removeForex}
          onForexSubmit={this.onForexSubmit}
          onForexInput={this.onForexInput}
          preferences={preferences}
          darkmode={darkmode}
          inactiveForex={this.inactiveForex}
          isMobile={isMobile}
          loadingForex={loadingForex}
          forexFilter={forexFilter}
        />
      );
    }

    if (activePrefUpper == "settings" || activePrefUpper == "devices" || activePrefUpper == "clock") {
      activePrefBox = (
        <Settings
          isAdmin={this.state.isAdmin}
          isMobile={isMobile}
          deviceArr={deviceArr}
          handleSettingsChange={this.handleSettingsChange}
          preferences={preferences}
          uid={uid}
          darkmode={darkmode}
          handleDarkModeChange={this.handleDarkModeChange}
          togglePrefSettings={this.togglePrefSettings}
          getUserData={this.getUserData}
          activePref={this.state.activePref}
        />
      );
    }

    if (activePrefUpper == "store") {
      activePrefBox = <Store uid={uid} darkmode={darkmode} preferences={preferences} togglePrefSettings={this.togglePrefSettings} />;
    }

    if (activePref == "profile") {
      activePrefBox = <Profile isMobile={isMobile} />;
    }
    if (activePref == "admin") {
      activePrefBox = <Admin isMobile={isMobile} uid={uid} mongoStocks={mongoStocks} screenWidth={this.props.screenWidth} />;
    }

    //after rest to override- activePref
    if (activePrefUpper == "weather") {
      activePrefBox = (
        <Weather
          uid={uid}
          darkmode={darkmode}
          isMobile={isMobile}
          locations={this.state.locations}
          weatherData={this.state.weatherData}
          getUserData={this.getUserData}
          handleTempChange={this.handleTempChange}
          preferences={preferences}
          toggleWeather={this.toggleWeather}
          removeFromUser={this.removeFromUser}
        />
      );
    }

    if (activePrefUpper == "news") {
      activePrefBox = <News uid={uid} darkmode={darkmode} isMobile={isMobile} />;
    }

    let userPath = dir.substr(1);
    let userPage = dir.charAt(0) == "@";

    let main = <div />;
    //   quotron.co/timeline

    let isHome = dir == "/" || dir == "";
    if (uid) {
      isHome = false;
    }

    main = uid ? (
      <div style={{ display: "flex" }}>
        <div
          className={"listBox"}
          style={{
            opacity: showSettingsMobile ? 0.1 : 1,
            minHeight: this.props.screenHeight,
            height: showSettingsMobile ? this.props.screenHeight : "auto",
          }}
        >
          {this.state.user?.phoneNumber || (this.state.user?.email && this.state.user?.emailVerified) ? (
            <div className={"preferences"} style={{ marginTop: isMobile ? 74 : 14 }}>
              <PrefMenu
                isTrail={isTrail}
                darkmode={darkmode}
                togglePref={this.togglePref}
                togglePrefUpper={this.togglePrefUpper}
                activePref={activePref}
                activePrefUpper={activePrefUpper}
                isMobile={isMobile}
                isAdmin={isAdmin}
                screenWidth={this.props.screenWidth}
                preferences={preferences}
                deviceArr={deviceArr}
                uid={uid}
                loaded={loaded}
              />
              {deviceOutput && <div className={darkmode ? "displayErrorBoxDark" : "displayErrorBox"}>{deviceOutput}</div>}
              {activePrefBox}
            </div>
          ) : (
            <>
              <div className={"verifyEmailBox"} style={{ marginTop: isMobile ? 78 : 40 }}>
                {mode == "verifyEmail" && !verifyEmailError ? (
                  <img src={loadingDots} className={"loadingDots"} />
                ) : (
                  <>
                    <div className={darkmode ? "verifyEmailTextDark" : "verifyEmailText"}>verification email sent</div>
                    <div style={{ opacity: 0.5, marginTop: 5 }}>--</div>
                    <div onClick={() => this.sendVerification()} className={darkmode ? "verifyEmailResendDark" : "verifyEmailResend"}>
                      resend
                    </div>
                  </>
                )}
              </div>
              {this.state.resentVerifyEmail && <div className={"resetPasswordSent"}>verify email sent</div>}
              {verifyEmailError && (
                <div className={"authError"} style={{ marginTop: 14 }}>
                  email verification error
                </div>
              )}
            </>
          )}
        </div>
      </div>
    ) : (
      <>
        {/*not logged in */}
        {loaded && (
          <>
            {resetPassword ? (
              <ResetPassword
                oobCode={oobCode}
                finishedResetPassword={this.finishedResetPassword}
                isMobile={this.props.isMobile}
                cookies={this.props.cookies}
              />
            ) : (
              <>
                {dir == "activate" && <Activate darkmode={darkmode} />}
                {dir == "setup" && (
                  <Auth
                    mode={mode}
                    newDevice={this.props.newDevice}
                    isMobile={this.props.isMobile}
                    showResetComplete={showResetComplete}
                    cookies={this.props.cookies}
                    screenWidth={this.props.screenWidth}
                    darkmode={darkmode}
                    deviceCheck={this.deviceCheck}
                    step={step}
                  />
                )}
                {dir != "setup" && dir != "activate" && (
                  <>
                    {/*
                    <Unknown
                      splashPage={splashPage}
                      mode={mode}
                      isMobile={this.props.isMobile}
                      cookies={this.props.cookies}
                      screenWidth={this.props.screenWidth}
                      screenHeight={this.props.screenHeight}
                    />
                    */}
                    <Blank screenHeight={this.props.screenHeight} />

                    {/*
                    <iframe
                      src="https://quotron.wordpress.com/"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                    />
                    */}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );

    if (loaded && !uid && dir != "setup" && dir != "activate") {
      const externalUrl = "https://shop.quotron.co";
      window.location.href = externalUrl;
    }

    let closeBanner = () => {
      let { cookies } = this.props;
      let banner1 = cookies.get("banner1") || 0;
      cookies.set("banner1", Number(banner1) + 1, {
        path: "/", // Specify the cookie path
        maxAge: 60 * 60 * 24 * 30, // Cookie will last for 30 days (in seconds)
        // or use expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) for a specific date
      });
      this.setState({ banner: false });
    };

    return (
      <div className={darkmode ? "pageDark" : "page"} ref={this.pageRef}>
        {this.state.banner && uid && <Banner darkmode={darkmode} isMobile={isMobile} closeBanner={closeBanner} />}
        <Header
          uid={uid}
          darkmode={darkmode}
          logout={this.logout}
          isMobile={isMobile}
          toggleSettingsMobile={this.toggleSettingsMobile}
          showSettingsMobile={showSettingsMobile}
          dir={dir}
          screenWidth={this.props.screenWidth}
          setSplashPage={this.setSplashPage}
          togglePrefUpper={this.togglePrefUpper}
          activePrefUpper={this.state.activePrefUpper}
          togglePref={this.togglePref}
        />

        <SideBar
          showSettingsMobile={showSettingsMobile}
          toggleSettingsMobile={this.toggleSettingsMobile}
          openProfile={this.openProfile}
          openSettings={this.openSettings}
          openSports={this.openSports}
          screenHeight={this.props.screenHeight}
          uid={uid}
          logout={this.logout}
          isAdmin={isAdmin}
          openAdmin={this.openAdmin}
          isMobile={isMobile}
          dir={dir}
          darkmode={darkmode}
          setSplashPage={this.setSplashPage}
        />

        <div style={{ display: "flex", alignSelf: "stretch" }}>
          <div
            className={"listBox"}
            style={{
              opacity: showSettingsMobile ? (isHome ? 1 : 0.1) : 1,
              minHeight: this.props.screenHeight - 80,
              height: showSettingsMobile ? this.props.screenHeight : "auto",
            }}
          >
            {main}
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Home));
