import React, { useEffect, useState } from "react";
import Switch from "react-input-switch";
import Axios from "axios";
import SwipeToDelete from "react-swipe-to-delete-ios";
import { getDatabase, ref, set } from "firebase/database";
import closeX from "../../imgs/closeX.png";
import closeXDark from "../../imgs/closeXDark.png";

import RssButton from "./RssButton";

let Weather = ({
  uid,
  darkmode,
  isMobile,
  locations,
  weatherData,
  getUserData,
  preferences,
  handleTempChange,
  toggleWeather,
  removeFromUser,
}) => {
  let [location, setLocation] = useState("");
  let [error, setError] = useState(false);

  let newLocation = (e) => {
    e.preventDefault();
    var options = {
      method: "POST",
      //url: "http://localhost:80/location/",
      url: "https://quotron.co/location/",
      data: {
        location,
        uid,
      },
    };
    setError(false);
    Axios.request(options).then((res) => {
      if (res.data?.status == "ok") {
        setLocation("");
        getUserData();
      } else {
        console.log("err", res);
        setError(true);
      }
    });
  };

  let showLocations = weatherData?.map((a, k) => {
    let condition = a.data?.current?.condition?.text || "";
    if (condition == "Sunny") {
      condition += " ☀️";
    }

    let showTemp = Math.round(Number(a.data?.current?.temp_f) || 0);
    let tempSymbol = `°F`;
    if (preferences.temp == "celcius") {
      showTemp = Math.round(Number(a.data?.current?.temp_c) || 0);
      tempSymbol = `°C`;
    }

    return (
      <SwipeToDelete
        key={a.postal}
        onDelete={() => removeFromUser("weather", a.postal)}
        disabled={!isMobile}
        deleteWidth={40}
        deleteThreshold={40}
        deleteColor={"none"}
        className={"swipeRowWeather"}
        deleteComponent={
          isMobile ? (
            <img
              src={darkmode ? closeXDark : closeX}
              className={"removeButtonXSwipe"}
            />
          ) : (
            <div />
          )
        }
      >
        <div
          onClick={() => toggleWeather(a.postal, a.inactive)}
          key={k}
          className={darkmode ? "favListItemDark" : "favListItem"}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            height: 54,
            alignSelf: "stretch",
            marginBottom: 1,
          }}
        >
          <div className={"flexRow"} style={{ alignItems: "center" }}>
            <RssButton
              onClick={() => false && toggleWeather(a.postal, a.inactive)}
              activeRow={true}
              inactive={a.inactive}
              darkmode={darkmode}
              offOpacity={0.08}
              marginLeft={-4}
            />
            <div style={{ marginLeft: 6 }}>
              <div style={{ fontSize: 16, color: darkmode ? "#ccc" : "#555" }}>
                {a.city}
              </div>
              <div style={{ fontWeight: 300 }}>{a.postal}</div>
            </div>
          </div>
          <div style={{ textAlign: "right" }}>
            <div style={{ marginTop: -4, marginBottom: 4 }}>{condition}</div>
            <div
              className={"flexRow"}
              style={{ fontSize: 20, justifyContent: "flex-end" }}
            >
              <div>{showTemp}</div>
              <div style={{ fontSize: 14, marginTop: -6 }}>{tempSymbol}</div>
            </div>
          </div>
        </div>
      </SwipeToDelete>
    );
  });

  let toggleTemp = (
    <div className={"settingsChangeText"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 26,
          width: 90,
        }}
      >
        <div
          style={{ opacity: darkmode ? 0.9 : 0.6, fontSize: 18 }}
        >{`°F`}</div>
        <div style={{ marginLeft: 4, marginRight: 4 }}>
          <Switch
            style={{ height: 18, width: 28, marginTop: -3 }}
            styles={{
              track: {
                backgroundColor: darkmode ? "#555" : "#bbb",
              },
              trackChecked: {
                backgroundColor: darkmode ? "#728cd4" : "#99ace0",
              },
            }}
            onChange={handleTempChange}
            value={preferences.temp == "celcius" ? 1 : 0}
          />
        </div>
        <div
          style={{ opacity: darkmode ? 0.9 : 0.6, fontSize: 18 }}
        >{`°C`}</div>
      </div>
    </div>
  );

  return (
    <div
      className={darkmode ? "prefBoxDark" : "prefBox"}
      style={{ width: 350, marginTop: 8 }}
    >
      <div
        className={"flexRow"}
        style={{
          alignSelf: "stretch",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <form
          className={"flexRow"}
          onSubmit={newLocation}
          style={{ marginBottom: 2, flex: 1, paddingRight: 4 }}
        >
          <input
            type="text"
            className={darkmode ? "weatherTextBoxDark" : "weatherTextBox"}
            value={location}
            onChange={(e) => {
              setError(false);
              setLocation(e.target.value);
            }}
            placeholder="City / Postal Code"
          />
          <button
            type={"submit"}
            className={"flexCenter"}
            style={{
              height: 42,
              border: "1px solid #888",
              borderRadius: 4,
              width: 44,
              marginLeft: 4,
              fontSize: 30,
              color: darkmode ? "#FFF" : "#333",
              fontWeight: 100,
              paddingBottom: 5,
            }}
          >
            {`+`}
          </button>
        </form>
        {toggleTemp}
      </div>
      <div style={{ marginTop: 10, alignSelf: "stretch" }}>{showLocations}</div>
      {error && (
        <div
          style={{
            alignSelf: "stretch",
            textAlign: "center",
            fontWeight: 300,
          }}
        >
          error finding location
        </div>
      )}
    </div>
  );
};

export default Weather;
