import React, { useEffect, useState } from "react";

import useWebSocket from "react-use-websocket";
const socketUrl = "wss://quotron.co:7072";

function LittleSocket(props) {
  const [messageHistory, setMessageHistory] = useState([]);
  let [filter, setFilter] = useState("");

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log("opened"),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (lastMessage !== null) {
      let showDate = new Date().toLocaleString();
      lastMessage.date = showDate;
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);

  let historyReverse = messageHistory.reverse();
  let showHistory = historyReverse.map((a, k) => {
    let showId = a.data.split("~")[1];
    if (filter) {
      let idNon = showId?.replaceAll("-", "");
      let filterNon = filter?.replaceAll("-", "");

      if (idNon.includes(filterNon)) {
        return (
          <div
            key={k}
            style={{
              marginTop: 3,
              display: "flex",
              alignSelf: "stretch",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>{showId}</div> <div>{a.date}</div>
          </div>
        );
      }
    } else {
      return (
        <div
          key={k}
          style={{
            marginTop: 3,
            display: "flex",
            alignSelf: "stretch",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>{showId}</div> <div>{a.date}</div>
        </div>
      );
    }
  });

  return (
    <div className={"flexCol"}>
      newest to top - localTime
      <input
        type="text"
        className={"portfolioShares"}
        style={{ minWidth: 240, marginBottom: 4 }}
        value={filter}
        onChange={() => setFilter(event.target.value)}
        placeholder={"0000-000-000-000-000"}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
          height: 200,
          border: "1px solid #999",
          width: 326,
          marginBottom: 20,
        }}
      >
        {showHistory}
      </div>
    </div>
  );
}
export default LittleSocket;
