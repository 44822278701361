import React, { useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import qtronW3 from "../imgs/qtronW3.png";
import qtronB3 from "../imgs/qtronB3.png";

function Header(props) {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  let history = useHistory();
  let handleActivate = useCallback(() => {
    history.push("/activate"), [history];
  });

  const isSticky = (e) => {
    const header = document.querySelector(".top");
    const scrollTop = window.scrollY;
    scrollTop >= 1 && props.isMobile ? header.classList.add("topBorder") : header.classList.remove("topBorder");
  };

  let { dir, uid, darkmode, isMobile, setSplashPage, activePrefUpper } = props;
  let userPage = dir.charAt(0) == "@";
  let isHome = dir == "/" || dir == "";
  if (props.uid) {
    isHome = false;
  }

  let handleLogin = () => {
    history.push("/setup?step=login"), [history];
  };

  //cog styles

  let activeMenu = darkmode ? "prefMenuItemBlue " : "prefMenuItemDark ";
  let inainactiveMenu = darkmode ? "prefMenuItemLight no-hover" : "prefMenuItem no-hover";

  let smallCog = {
    height: 36,
    marginTop: 20,
    marginRight: 6,
    borderWidth: props.activePrefUpper == "settings" ? 1 : 0,
  };

  let bigCog = {
    height: 36,
    marginTop: 24,
    marginRight: 6,
    borderWidth: props.activePrefUpper == "settings" ? 1 : 0,
  };

  //Splash = dir != "setup" && dir != "activate" &&
  if (isHome) return null;
  return (
    <div
      className={darkmode && !isHome ? "top darkBG noselect" : "top noselect"}
      style={{
        position: props.splash ? "absolute" : props.isMobile ? "fixed" : "static",
        height: props.isMobile ? 74 : 80,
      }}
    >
      {isHome && (
        <div
          style={{
            backgroundColor: "#000", //isHome ? "#333" : "#fbf7f5",
            position: "absolute",
            width: props.screenWidth,
            opacity: 1,
            zIndex: 5,
            height: props.isMobile ? 74 : 80,
          }}
        ></div>
      )}
      <div
        style={{
          maxWidth: 900,
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          zIndex: 10,
          backgroundColor: isHome
            ? "#000" //"transparent"
            : darkmode
            ? "#222"
            : "#fbf7f5",
        }}
      >
        <a style={{ textDecoration: "none" }} href="/">
          {isHome ? (
            <img
              src={isHome ? qtronW3 : darkmode ? qtronW3 : qtronB3}
              style={{
                width: 200,
                height: 58,
                marginTop: props.isMobile ? 5 : 10,
                marginLeft: props.isMobile ? 8 : -3,
                opacity: isHome ? 1 : 0.5,
              }}
            />
          ) : (
            <img
              src={isHome ? qtronW3 : darkmode ? qtronW3 : qtronB3}
              style={{
                width: 83,
                height: 24,
                marginTop: props.isMobile ? 25 : 27,
                marginLeft: props.isMobile ? 18 : 7,
                opacity: isHome ? 1 : 0.5,
              }}
            />
          )}
        </a>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: props.isMobile ? 82 : 126,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {isHome && !isMobile && (
              <div className={"flexRow roboto500"}>
                <div
                  className={"headerButton"}
                  onClick={() => setSplashPage("features")}
                  style={{
                    color: "#fff",
                    marginTop: 31,
                    fontSize: 15,
                    marginRight: 30,
                  }}
                >
                  FEATURES
                </div>
                <div
                  className={"headerButton"}
                  onClick={() => setSplashPage("pricing")}
                  style={{
                    color: "#fff",
                    marginTop: 31,
                    fontSize: 15,
                    marginRight: 30,
                  }}
                >
                  PRICING
                </div>
                <div
                  className={"headerButton"}
                  onClick={() => setSplashPage("faq")}
                  style={{
                    color: "#fff",
                    marginTop: 31,
                    fontSize: 15,
                    marginRight: 30,
                  }}
                >
                  FAQ
                </div>
                <div
                  className={"headerButton"}
                  onClick={() => handleActivate()}
                  style={{
                    color: "#fff",
                    marginTop: 31,
                    fontSize: 15,
                    marginRight: 30,
                    width: 110,
                  }}
                >
                  DEVICE SETUP
                </div>
                <div
                  className={"headerButton"}
                  onClick={() => setSplashPage("support")}
                  style={{
                    color: "#fff",
                    marginTop: 31,
                    fontSize: 15,
                    marginRight: 30,
                  }}
                >
                  SUPPORT
                </div>
                <div
                  className={"headerButton"}
                  onClick={() => handleLogin()}
                  style={{
                    color: "#fff",
                    marginTop: 31,
                    fontSize: 15,
                    marginRight: 30,
                  }}
                >
                  LOGIN
                </div>
              </div>
            )}
            {props.uid && (
              <div
                className={props.activePrefUpper == "settings" ? activeMenu : inainactiveMenu}
                style={isMobile ? smallCog : bigCog}
                onClick={() => {
                  if (activePrefUpper == "settings") {
                    props.togglePrefUpper("finance");
                    props.togglePref("stocks");
                  } else {
                    props.togglePrefUpper("settings");
                  }
                }}
              >
                <div
                  className={"fa fa-cog"}
                  style={{
                    marginRight: 1,
                    opacity: 0.65,
                    paddingLeft: 3,
                    paddingRight: 2,
                    fontSize: 19,
                  }}
                ></div>
              </div>
            )}
            {!(isHome && !isMobile) && (
              <div>
                <button
                  className={"settingsButton"}
                  style={{ marginTop: props.isMobile ? 20 : 24, width: 38 }}
                  onClick={props.toggleSettingsMobile}
                >
                  {props.showSettingsMobile ? (
                    <div>
                      <div
                        className={"timesLeft"}
                        style={{
                          height: 1.5,
                          width: 25,
                          backgroundColor: isHome ? "#fff" : "#777",
                          marginBottom: 4,
                          marginTop: 6,
                          marginRight: 1,
                        }}
                      />
                      <div
                        className={"timesRight"}
                        style={{
                          height: 1.5,
                          width: 25,
                          backgroundColor: isHome ? "#fff" : "#777",
                          marginBottom: 4,
                          marginTop: -6,
                          marginRight: 1,
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          height: 1.5,
                          width: 22,
                          backgroundColor: isHome ? "#fff" : "#777",
                          marginBottom: 5,
                          marginRight: 1,
                        }}
                      />
                      <div
                        style={{
                          height: 1.5,
                          width: 22,
                          backgroundColor: isHome ? "#fff" : "#777",
                          marginBottom: 5,
                          marginRight: 1,
                        }}
                      />
                      <div
                        style={{
                          height: 1.5,
                          width: 22,
                          backgroundColor: isHome ? "#fff" : "#777",
                          marginRight: 1,
                        }}
                      />
                    </div>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
