import React from "react";
import SwipeToDelete from "react-swipe-to-delete-ios";
import plus from "../../imgs/plus.png";
import closeX from "../../imgs/closeX.png";
import closeXDark from "../../imgs/closeXDark.png";
import closeXWhite from "../../imgs/closeXWhite.png";
import RssButton from "./RssButton";

function ListForex(props) {
  let { forexData, forexInput, activeForex, networkErr, preferences, darkmode, isMobile, loadingForex, forexFilter = [] } = props;

  let showStockSort = "abc";
  let showSortCaret = "fa-caret-up";

  var options = {
    timeZone: "America/New_York",
    hour12: false,
    hour: "2-digit",
  };
  var d = new Date();
  let hour = d.toLocaleString("en-US", options);
  let day = d.getDay();
  let minute = d.getMinutes();
  hour = Number(hour);

  let actDay = day;

  /*
  if (hour <= 9 || (hour == 9 && minute < 30)) {
    actDay = day - 1;
  }
  if (
    day == 6 ||
    day == 0 ||
    (day == 1 && hour <= 9) ||
    (day == 1 && hour == 9 && minute < 30)
  ) {
    actDay = 5;
  }

  let yesterday = actDay - 1;
  if (yesterday == 0) {
    yesterday = 5;
  }
  */

  forexData = forexData.filter((a) => a);

  let showFavs = forexData.map((a, k) => {
    // upDown difference
    let stockPrice = a.price.toFixed(2);

    /*
    if (hour <= 9 || (hour == 9 && minute < 30)) {
      stockPrice = a.series[actDay]?.["16:0"] || stockPrice;
    }
    let yesterdayEod = a.series[yesterday]?.["16:0"] || stockPrice;
    */

    let upDown = preferences.upDown || "percentage";
    let difference = 0;
    let difSymbol = "";
    /*
    if (upDown == "percentage") {
      difference = ((stockPrice / yesterdayEod) * 100 - 100).toFixed(2);
      difSymbol = "%";
    } else {
      difference = (stockPrice - yesterdayEod).toFixed(2);
    }*/

    let weatherButtonCss = darkmode ? "favListItemDark" : "favListItem";
    if (activeForex == k) {
      weatherButtonCss = darkmode ? "favListItemDarkActive" : "favListItemActive";
    }
    let showPrice = a.price.toFixed(2);

    let changeColor = "#0c870c";
    let showCaret = "fa fa-caret-up";
    if (activeForex == k) {
      changeColor = "#5fff5f";
    }
    if (difference < 0) {
      changeColor = "#bd0101";
      showCaret = "fa fa-caret-down";
      if (activeForex == k) {
        changeColor = "#ffbe9e";
      }
    }

    let slashName = a.name.substring(0, 3) + "/" + a.name.substring(3, 6);

    return (
      <SwipeToDelete
        key={a.name}
        onDelete={() => props.removeForex(a.name)}
        disabled={!isMobile}
        deleteWidth={40}
        deleteThreshold={40}
        deleteColor={"none"}
        className={"swipeRow"}
        deleteComponent={isMobile ? <img src={darkmode ? closeXDark : closeX} className={"removeButtonXSwipe"} /> : <div />}
      >
        <div key={k} className={"favListBox"}>
          <button
            className={weatherButtonCss}
            onClick={() => (isMobile ? props.inactiveForex(a.name, a.inactive) : props.makeForexActive(k))}
          >
            <div className={"favListLeft"}>
              <RssButton
                onClick={() => props.inactiveForex(a.name, a.inactive)}
                activeRow={true}
                inactive={a.inactive}
                darkmode={darkmode}
              />
              <div className={"favListSymbol"}>{slashName}</div>
              <div className={"favListName"}>{}</div>
            </div>
            <div className={"favListRight"}>
              <div className={`${showCaret} favItemCaret`} style={{ color: changeColor, opacity: 0 }}></div>
              <div className={"favItemChange"} style={{ color: changeColor, opacity: 0 }}>
                {`${difference}${difSymbol}`}
              </div>
              <div className={"favItemStockPrice"}>{a.price.toFixed(4)}</div>
            </div>
          </button>
          {activeForex == k && !isMobile && (
            <button onClick={() => props.removeForex(a.name)}>
              <img src={darkmode ? closeXWhite : closeX} className={"removeButtonX"} />
            </button>
          )}
        </div>
      </SwipeToDelete>
    );
  });

  let forexClickRes = (a) => {
    props.onForexSubmit(null, a.symbol);
  };

  let filteredList = forexFilter?.map((a, k) => {
    let slashName = a.symbol.substring(0, 3) + "/" + a.symbol.substring(3, 6);

    return (
      <div key={k} className={"favListBox"}>
        <button className={darkmode ? "favListItemDark" : "favListItem"} onClick={() => forexClickRes(a)}>
          <div className={"favListLeftFilteredForex"}>
            <div className={"favListSymbol"}>{slashName}</div>
            <div className={"favListName"}>{a.name}</div>
          </div>
          <div className={"favListRight"}>
            <div className={"searchSubmitButtonBoxFilter"}>
              <div className={"submitPlusFontFilter"}>+</div>
            </div>
          </div>
        </button>
      </div>
    );
  });

  return (
    <div className={darkmode ? "prefBoxDark" : "prefBox"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "stretch",
          justifyContent: "space-between",
          paddingLeft: 5,
        }}
      >
        <div className={"weatherForm"}>
          <form
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onSubmit={props.onForexSubmit}
          >
            <input
              type="text"
              className={darkmode ? "searchSubmitBoxDark" : "searchSubmitBox"}
              value={forexInput}
              onChange={props.onForexInput}
              placeholder="GBP/JPY"
            />
            {loadingForex ? (
              <div className={"searchSubmitButtonBox"}>
                <div className={"loader"} style={{ marginLeft: 2, marginTop: 6 }} />
              </div>
            ) : (
              <div className={"searchSubmitButtonBox"}>
                <input type="submit" className={darkmode ? "searchSubmitButtonDark" : "searchSubmitButton"} value="" />
                <div className={darkmode ? "submitPlusFontDark" : "submitPlusFont"}>+</div>
              </div>
            )}
          </form>
        </div>
        <div onClick={() => props.alternateSort("stocks")} className={"favSort "} style={{ display: "none" }}>
          <div className={`fa ${showSortCaret} favSortCaret `}></div>
          <div className={""}>{showStockSort}</div>
        </div>
      </div>
      {filteredList.length > 0 && (
        <>
          <div className={"favListFiltered"}>{filteredList}</div>
          <div className={"smallDivider"} />
        </>
      )}
      {networkErr && <div className={"networkErr"}>{networkErr}</div>}
      <div className={"favList"}>{showFavs}</div>
    </div>
  );
}

export default ListForex;
