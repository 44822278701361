import React, { useState, useEffect } from "react";
import Axios from "axios";

let Uptime = ({ isMobile }) => {
  let [arr, setArr] = useState([]);
  let [expand, setExpand] = useState(null);

  useEffect(() => {
    getUp();
  }, []);

  let getUp = async () => {
    /*
    if (
      !(
        window.location.hostname == "dev.quotron.co" ||
        window.location.hostname == "localhost"
      )
    )
      return;
      */
    var options = {
      method: "GET",
      url: "http://dev.quotron.co/upTime",
      headers: {
        "Content-Type": "application/json",
      },
    };
    let res = await Axios.request(options);
    let arr = res?.data || [];

    setArr(arr);
  };

  function getLast7DaysInEST() {
    const daysArray = [];
    const EST_OFFSET = 5 * 60 * 60 * 1000; // 5 hours in milliseconds

    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setUTCHours(0, 0, 0, 0); // Set to start of day in UTC
      date.setUTCDate(date.getUTCDate() - i); // Go back 'i' days

      const startInUTC = date.getTime();
      const endInUTC = startInUTC + 24 * 60 * 60 * 1000 - 1; // End of day in UTC

      // Convert UTC to EST by subtracting the offset
      const startInEST = startInUTC - EST_OFFSET;
      const endInEST = endInUTC - EST_OFFSET;
      const estStartDate = new Date(startInEST);
      const title = `${estStartDate.getMonth() + 1}/${estStartDate.getDate()}`;

      daysArray.push({ start: startInEST, end: endInEST, title });
    }

    return daysArray;
  }

  const last7 = getLast7DaysInEST();

  let format = (timestamp) => {
    const date = new Date(timestamp);

    const utcTime = date.getTime();

    //weird timezone fix
    const estOffset = -3 * 60 * 60 * 1000;

    const estTime = new Date(utcTime - estOffset);

    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const formattedTime = estTime.toLocaleTimeString("en-US", options);

    return formattedTime;
  };

  let bars = last7?.map((a, k) => {
    let days = arr?.filter(
      (b) => b.timestamp >= a.start && b.timestamp < a.end
    );

    let up = days.filter((b) => b.string)?.length;
    let bot = days.length;
    let backgroundColor = "#fbf7f5";
    let borderWidth = 1;
    let color = "#333";
    if (up == bot) {
      backgroundColor = "#38e1b7";
      borderWidth = 0;
      color = "#fff";
    }

    let mins = [];
    if (k == expand) {
      mins = days?.filter((b) => !b.string);
      mins = mins?.map((b, p) => {
        let showTime = format(b.timestamp);
        return (
          <div
            key={p}
            className={"profileTextSub"}
            style={{
              height: 20,
              width: 54,
              fontSize: 12,
            }}
          >
            {showTime}
          </div>
        );
      });
    }

    let newExpand = k;
    if (expand == k) {
      newExpand = null;
    }
    return (
      <div
        className={"flexCol profileText"}
        onClick={() => setExpand(newExpand)}
        key={k}
      >
        <div className={"profileTextSub"} style={{ fontSize: 14 }}>
          {a.title}
        </div>
        <div
          className={"flexCol"}
          style={{
            height: 50,
            width: 47,
            backgroundColor,
            alignItems: "center",
            marginRight: 1,
            color,
            fontSize: 14,
            border: "0px solid #888",
            borderWidth,
          }}
        >
          <div style={{ width: 40 }}>{`${up}`}</div>
          <div
            style={{
              height: 0,
              width: 40,
              border: "0px solid #fff",
              borderTopWidth: 0.5,
              borderColor: color,
              marginTop: 1,
              marginBottom: 1,
            }}
          />
          <div style={{ width: 40 }}>{`${bot}`}</div>
        </div>
        {expand == k && <div>{mins}</div>}
      </div>
    );
  });

  return (
    <div className={"prefMenu flexCol"}>
      <div
        className={"settingsTitle"}
        style={{ marginTop: 10, marginBottom: 4 }}
      >
        uptime [est]
      </div>
      <div className={"flexRow"}>{bars}</div>
    </div>
  );
};
export default Uptime;
